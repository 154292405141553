
<div v-if="!allowCodeEntry" class="login-form-container">
  <v-card-title v-if="showBackButton" class="ml-0 pl-0">
    <v-btn outlined icon @click="hideSelf()">
      <v-icon> mdi-arrow-left </v-icon>
    </v-btn>
    <v-spacer />
  </v-card-title>
  <div class="text-h5 font-weight-bold">Login</div>
  <div class="text-body-1 mt-2">
    Login with your mobile phone to join the Community.
  </div>
  <div class="d-flex align-center mt-6">
    <div style="width: 12rem" class="pr-2">
      <v-autocomplete
        outlined
        v-model="countryCode"
        :items="countryCodes"
        label="Country"
        prepend-icon="mdi-map-marker-outline"
        item-text="name"
        item-value="key"
        :disabled="loading"
      ></v-autocomplete>
    </div>
    <div style="width: 100%">
      <v-text-field
        :loading="loading"
        outlined
        v-model="phone"
        label="Phone"
        placeholder="Phone"
        :rules="[validPhone]"
        @input="watchNumber"
        :disabled="loading"
      ></v-text-field>
    </div>
  </div>
  <div v-if="newAccount" style="width: 100%">
    <v-form ref="loginForm">
      <v-row>
        <v-col cols="6">
          <v-text-field
            :loading="loading"
            outlined
            v-model="firstName"
            label="First Name"
            placeholder="John"
            :rules="[validFirst]"
            :disabled="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            :loading="loading"
            outlined
            v-model="lastName"
            label="Last Name"
            placeholder="Smith"
            :rules="[validLast]"
            :disabled="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        :loading="loading"
        outlined
        v-model="email"
        label="Email"
        placeholder="JohnSmith1995@gmail.com"
        :rules="[validEmail]"
        :disabled="loading"
      ></v-text-field>
      <v-text-field
        :loading="loading"
        outlined
        v-model="username"
        label="Username"
        placeholder="JohnSmith1995"
        :disabled="loading"
      ></v-text-field>
    </v-form>
    <div>
      <v-flex class="d-flex">
        <v-checkbox dense v-model="termsAndConditions"></v-checkbox>
        <span class="mt-2"
          >I Agree to the
          <a href="https://www.velocityaccess.io/terms/" target="_blank">
            Terms and Conditions</a
          ></span
        >
      </v-flex>
      <v-flex d-flex class="justify-center">
        <v-btn :disabled="!termsAndConditions" @click="submitEmail" outlined>
          Submit
        </v-btn>
      </v-flex>
    </div>
  </div>
</div>
<div v-else class="login-form-container">
  <div class="text-h5 font-weight-bold">Login</div>
  <div class="text-body-1 mt-2">
    Enter the authorization code that was just sent to your mobile phone and
    email address
  </div>
  <div class="d-flex align-center justify-center mt-6">
    <div v-if="allowCodeEntry" style="width: 50%">
      <v-text-field
        :loading="loading"
        outlined
        v-model="code"
        label="Auth Code"
        placeholder="Code"
        @input="watchCode"
        :disabled="loading"
        :rules="[validCode]"
        maxlength="6"
      ></v-text-field>
    </div>
  </div>
  <div class="d-flex align-center justify-center mt-6">
    <v-btn outlined @click="reset()">
      <v-icon class="pr-3"> mdi-arrow-left </v-icon> Back
    </v-btn>
  </div>
</div>
