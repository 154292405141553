<template>
  <v-dialog
    style="border-radius: 20px; top: 64px !important"
    width="500"
    transition="dialog-bottom-transition"
    value="true"
    persistent
    @click:outside="saveProduct(modifiedProduct)"
  >
    <v-card>
      <v-card-title>
        <span class="spanBoldMedium"> Add a Ticket Type </span>
        <v-spacer />
        <v-btn icon @click="deleteProduct">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-flex class="pa-6">
        <span class="spanBoldSmall">Name</span>
        <v-text-field
          dense
          class="pa-0 ma-0"
          v-model="modifiedProduct.name"
          clear-icon="mdi-close-circle"
          clearable
          outlined
          single-line
          :placeholder="
            modifiedProduct.placeholder
              ? modifiedProduct.placeholder['name']
              : modifiedProduct.name
          "
          type="text"
          @click:clear="modifiedProduct.name = ''"
          color="velocityBlue"
          hide-details
        >
        </v-text-field>

        <v-flex class="mr-2 mt-3 mb-6">
          <span class="spanBoldSmall">Description</span>
          <v-text-field
            dense
            class="pa-0 ma-0"
            v-model="modifiedProduct.description"
            clear-icon="mdi-close-circle"
            outlined
            single-line
            color="velocityBlue"
            hide-details
          />
        </v-flex>

        <v-flex v-if="!isSeatsIOIntegration" class="mr-2 mt-3 mb-6">
          <span class="spanBoldSmall">Capacity</span>
          <v-text-field
            type="number"
            dense
            class="pa-0 ma-0"
            v-model.number="modifiedProduct.quantity"
            clear-icon="mdi-close-circle"
            outlined
            single-line
            :placeholder="`${
              modifiedProduct.placeholder
                ? modifiedProduct.placeholder['quantity']
                : modifiedProduct.quantity
            }`"
            color="velocityBlue"
            hide-details
          />
        </v-flex>
        <v-flex v-else class="mr-2 mt-3 mb-6 d-flex flex-column">
          <span class="spanBoldSmall">Capacity</span>
          <span> {{ modifiedProduct.quantity }} </span>
        </v-flex>

        <v-flex class="d-flex">
          <v-flex xs4 sm4 md4 lg4 class="d-flex justify-center">
            <v-flex>
              <span
                style="cursor: pointer"
                @click="toggleActive"
                class="spanBoldSmall"
              >
                Available for Purchase
              </span>
              <v-btn
                class="my-0 py-0"
                icon
                :color="`${modifiedProduct.active ? 'blue' : ''}`"
                @click="toggleActive"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">
                      {{
                        modifiedProduct.active
                          ? "mdi-checkbox-marked"
                          : "mdi-checkbox-blank-outline"
                      }}
                    </v-icon>
                  </template>
                  {{
                    modifiedProduct.active ? "Purchaseable" : "Not Purchaseable"
                  }}
                </v-tooltip>
              </v-btn>
            </v-flex>
          </v-flex>
          <v-flex xs4 sm4 md4 lg4 class="d-flex justify-center">
            <v-flex>
              <span
                style="cursor: pointer"
                class="spanBoldSmall"
                @click="toggleAccessible"
              >
                Handicap Accessible
              </span>
              <v-btn
                class="my-0 py-0"
                icon
                :color="`${modifiedProduct.accessible ? 'blue' : ''}`"
                @click="toggleAccessible"
                :readonly="isSeatsIOIntegration"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">
                      {{
                        modifiedProduct.accessible
                          ? "mdi-checkbox-marked"
                          : "mdi-checkbox-blank-outline"
                      }}
                    </v-icon>
                  </template>
                  {{
                    modifiedProduct.accessible
                      ? "Is Handicap Accessible"
                      : "Not Handicap Accessible"
                  }}
                </v-tooltip>
              </v-btn>
            </v-flex>
          </v-flex>
          <v-flex xs4 sm4 md4 lg4 class="d-flex justify-center">
            <v-flex>
              <span
                style="cursor: pointer"
                class="spanBoldSmall"
                @click="toggleTaxable"
              >
                Tax Exempt
              </span>
              <v-btn
                class="my-0 py-0"
                icon
                :color="`${modifiedProduct.tax_exempt ? 'blue' : ''}`"
                @click="toggleTaxable"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">
                      {{
                        modifiedProduct.tax_exempt
                          ? "mdi-checkbox-marked"
                          : "mdi-checkbox-blank-outline"
                      }}
                    </v-icon>
                  </template>
                  {{
                    modifiedProduct.tax_exempt ? "Is Tax Exempt" : "Is Taxable"
                  }}
                </v-tooltip>
              </v-btn>
            </v-flex>
          </v-flex>
        </v-flex>
        <v-divider class="my-2" />
        <TicketTypePricing
          :product="modifiedProduct"
          @savePricing="savePricing"
        />
        <v-divider class="my-6" />
        <v-flex>
          <v-btn
            block
            class="velocityBlue white--text mb-3"
            @click="saveProduct(modifiedProduct)"
          >
            Finish
          </v-btn>
          <!-- <v-btn block outlined @click="deleteProduct"> Delete </v-btn> -->
        </v-flex>
      </v-flex>
    </v-card>
  </v-dialog>
</template>

<script>
import TicketTypePricing from "./TicketTypePricing.vue";
export default {
  props: { product: Object },
  components: { TicketTypePricing },
  name: "TicketTypeBuilder",
  data: () => ({
    editTicketType: true,
    modifiedProduct: {},
    quantity: null,
    validateMessages: [],
  }),
  created() {
    this.setModifiedProduct(this.product);
  },
  methods: {
    validateTicket(displayMessage) {
      this.validateMessages = [];
      if (this.modifiedProduct.pricing.length > 0) {
        for (var i = 0; i < this.modifiedProduct.pricing.length; i++) {
          let price = this.modifiedProduct.pricing[i];
          if (!price.name) {
            this.validateMessages.push(
              "One or more Price categories have an invalid name"
            );
          }
          if (price.price === null) {
            this.validateMessages.push(
              "One or more Price categories have an invalid price"
            );
          }
          if (price.price < 0) {
            this.validateMessages.push(
              "Price on product cannot be less than $0.00"
            );
          }
        }
      } else {
        this.validateMessages.push(
          "One or more Price categories have an invalid price"
        );
      }
      if (
        !this.modifiedProduct.name ||
        this.modifiedProduct.name.trim() === ""
      ) {
        this.validateMessages.push("Please provide a name for the ticket");
      }
      if (!this.modifiedProduct.price == null) {
        this.validateMessages.push("Please provide a price for the ticket");
      }
      if (displayMessage && this.validateMessages.length > 0) {
        this.$store.dispatch("setSnackBarMessage", {
          message: this.validateMessages[0],
          type: "FAILURE",
        });
        return false;
      }
      return true;
    },
    toggleAccessible() {
      if (!this.isSeatsIOIntegration) {
        this.modifiedProduct.accessible = !this.modifiedProduct.accessible;
      }
    },
    toggleTaxable() {
      this.modifiedProduct.tax_exempt = !this.modifiedProduct.tax_exempt;
    },
    toggleActive() {
      this.modifiedProduct.active = !this.modifiedProduct.active;
    },
    savePricing(pricing) {
      this.modifiedProduct.pricing = pricing;
    },
    saveProduct(product) {
      this.setModifiedProduct(product);
      let validated = this.validateTicket(true);
      if (validated) {
        this.$emit("saveProduct", this.modifiedProduct);
      }
    },
    deleteProduct() {
      this.$emit("deleteProduct");
    },
    setModifiedProduct(product) {
      if (product.quantity == -1) {
        product.quantity = "unlimited";
        this.$set(product, "quantity", "unlimited");
      }
      this.modifiedProduct = product;
    },
  },
  computed: {
    eventProducts() {
      return this.$store.getters.eventProducts;
    },
    isSeatsIOIntegration() {
      return (
        this.modifiedProduct.integrations &&
        this.modifiedProduct.integrations.sio
      );
    },
  },
  watch: {
    product(newVal) {
      this.setModifiedProduct(newVal);
    },
  },
};
</script>

<style scoped></style>
