
<div>
  <DefaultEventOptions
    class="mb-2"
    :timezone.sync="eventTimeSetup.timezone"
    :endDate.sync="eventTimeSetup.endDate"
    :endTime.sync="eventTimeSetup.endTime"
    :startDate.sync="eventTimeSetup.startDate"
    :startTime.sync="eventTimeSetup.startTime"
    :disableDatePickers="
      eventTimeSetup?.customDays?.length > 0 ||
      existingTimeslots?.length > 0 ||
      parentEventID != null
    "
    :disableTimePickers="
      existingTimeslots?.length > 0 || parentEventID != null
    "
  ></DefaultEventOptions>

  <div v-if="parentEventID" class="d-flex justify-center mt-6">
    <v-btn class="velocityBlue white--text" @click="viewParentEvent()">
      View Group Event
    </v-btn>
  </div>

  <a
    v-if="
      eventTimeSetup.endTime &&
      existingTimeslots?.length > 0 &&
      !parentEventID
    "
    class="spanBoldSmall"
    @click="openTimeslotTab"
  >
    Add more timeslots for Event
  </a>

  <v-checkbox
    v-if="showRecurringEventsOption"
    v-model="isEventRecurring"
    class="px-2 mb-2"
    color="velocityBlue"
    hide-details
    label="Would you like to set a recurring event?"
  ></v-checkbox>

  <span
    v-if="isEventRecurring && timeslotsToCreate.length > 0"
    class="spanSmallGrey mt-8 pa-3"
  >
    {{
      `${timeslotsToCreate.length} ${
        timeslotsToCreate.length == 1 ? "timeslot" : "timeslots"
      }`
    }}
    will be created.
  </span>

  <div v-if="isEventRecurring">
    <FrequencySelector
      :chosenWeekOfMonth.sync="eventTimeSetup.chosenWeekOfMonth"
      :customDays.sync="eventTimeSetup.customDays"
      :eventFrequency.sync="eventTimeSetup.eventFrequency"
      :selectedDays.sync="eventTimeSetup.selectedDays"
    ></FrequencySelector>

    <!-- <v-checkbox
      v-model="isMultipleEventsPerDay"
      class="px-2 mb-2"
      color="velocityBlue"
      hide-details
      label="Do you want more than one event per day?"
    ></v-checkbox>
    <div v-if="isMultipleEventsPerDay" class="mb-2">
      <div class="spanSmallGrey">Increments & Time Slots</div>
      <v-btn-toggle
        v-model="isInTimeIncrements"
        color="velocityBlue"
        mandatory
      >
        <v-btn
          :value="true"
          class="white--text"
          color="velocityBlue"
          rounded
          small
          >Increments</v-btn
        >
        <v-btn
          :value="false"
          class="white--text"
          color="velocityBlue"
          rounded
          small
          >Time Slots</v-btn
        >
      </v-btn-toggle>
    </div> -->

    <!-- <div v-if="isInTimeIncrements && isMultipleEventsPerDay">
      <span class="spanSmallGrey"> Time Increments (Minutes) </span>
      <v-select
        v-model="eventTimeSetup.eventIncrement"
        :items="minuteIncrements"
        dense
        hide-details
        outlined
      ></v-select>
    </div>
    <div v-else class="pa-3">
      <EventTimeSlots
        :timeslots.sync="eventTimeSetup.timeslots"
        @addTimeSlot="addTimeSlot"
        @removeTimeSlot="removeTimeSlot($event)"
      >
      </EventTimeSlots>
    </div> -->

    <div
      v-if="timeslotsToCreate.length > 0"
      class="d-flex justify-center mt-6"
    >
      <v-btn
        class="velocityBlue white--text"
        @click="saveTimeslotsToServer()"
      >
        Create Timeslots
      </v-btn>
    </div>
  </div>
</div>
