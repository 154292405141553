import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import Vuex from "vuex";
import "@fontsource/poppins/400.css"; // import only what's needed
import "@mdi/font/css/materialdesignicons.min.css";
import "../php-integration.js";

new Vue({
  vuetify,
  store,
  router,
  render: (hyperscript) => hyperscript(App),
}).$mount("#app");
