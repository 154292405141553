<template>
  <div>
    <v-btn class="velocityBlue white--text" @click="creatingEvent = true">
      <span class="textParagraph1 font-weight-bold">Create an Event</span>
      <v-icon class="ml-2" small> mdi-arrow-right </v-icon>
    </v-btn>
    <EventBuilderDraft
      v-if="creatingEvent"
      :creatingEvent="creatingEvent"
      @closeEvent="closeEvent"
    />
  </div>
</template>

<script>
import EventBuilderDraft from "@/views/events/EventBuilderDraft.vue";
export default {
  components: {
    EventBuilderDraft,
  },
  name: "CreateEventBtn",
  data: () => ({
    creatingEvent: false,
  }),
  methods: {
    closeEvent() {
      this.creatingEvent = false;
    },
  },
  watch: {
    creatingEvent(newVal) {},
  },
};
</script>

<style scoped></style>
