import * as types from "../mutation-types";

export default {
  state: {
    apps: [
      {
        name: "VAC",
        icon: "mdi-bat",
        color: "velocityBlue",
        viewable: false,
        path: "/admin",
        routes: [
          {
            name: "User Management",
            path: "/admin/user-management",
            icon: "mdi-account-question-outline",
          },
          {
            name: "Org Management",
            path: "/admin/org-management",
            icon: "mdi-office-building-cog-outline",
          },
        ],
      },
      {
        name: "Marketplace",
        icon: "mdi-shopping",
        color: "velocityBlue",
        viewable: true,
        path: "/marketplace",
        routes: [
          {
            name: "Browse Events",
            path: "/marketplace/search",
            icon: "mdi-lightning-bolt-outline",
          },
          {
            name: "My Wallet",
            path: "/marketplace/my-wallet",
            icon: "mdi-format-list-bulleted",
          },
          {
            name: "My Cart",
            path: "/marketplace/checkout",
            icon: "mdi-cart-outline",
          },
        ],
      },
      {
        name: "Overview",
        icon: "mdi-office-building-cog",
        color: "velocityBlue",
        viewable: false,
        path: "/overview",
        routes: [
          // {
          //   name: "Overview",
          //   path: "/overview",
          //   icon: "mdi-office-building-outline"
          // },
          {
            name: "Payments",
            path: "/overview/payments",
            icon: "mdi-account-cash-outline",
          },
          {
            name: "Settings",
            path: "/overview/settings",
            icon: "mdi-cog-outline",
          },
        ],
      },
      {
        name: "Ticketing",
        icon: "mdi-ticket",
        color: "velocityBlue",
        viewable: false,
        path: "/ticketing",
        routes: [
          // {
          //   name: "Overview",
          //   path: "/ticketing",
          //   icon: "mdi-office-building-outline"
          // },
          {
            name: "Events",
            path: "/ticketing/events",
            icon: "mdi-format-list-bulleted",
          },
          {
            name: "Calendar",
            path: "/ticketing/calendar",
            icon: "mdi-calendar-minus-outline",
          },
          // {
          //   name: "Venues",
          //   path: "/ticketing/venues",
          //   icon: "mdi-stadium-outline"
          // }
        ],
      },
      {
        name: "Store",
        icon: "mdi-store",
        color: "velocityBlue",
        viewable: true,
        path: "/store",
        routes: [
          {
            name: "Products",
            path: "/store/products",
            icon: "mdi-package",
          },
          {
            name: "Bundles",
            path: "/store/bundles",
            icon: "mdi-vector-combine",
          },
          {
            name: "Payment Readers",
            path: "/store/payment-readers",
            icon: "mdi-credit-card-fast-outline",
          },
        ],
      },
      {
        name: "Analytics",
        icon: "mdi-poll",
        color: "velocityBlue",
        viewable: false,
        path: "/analytics",
        routes: [
          {
            name: "Dashboard",
            path: "/analytics/dashboard",
            icon: "mdi-view-dashboard-outline",
          },
          {
            name: "Reports",
            path: "/analytics/reports",
            icon: "mdi-chart-box-outline",
          },
          {
            name: "Customer Search",
            path: "/analytics/customer_search",
            icon: "mdi-account-details",
          },
          // {
          //   name: "Settings",
          //   path: "/analytics/settings",
          //   icon: "mdi-cog-outline"
          // }
        ],
      },
      {
        name: "Loyalty",
        icon: "mdi-gift",
        color: "velocityBlue",
        viewable: true,
        path: "/loyalty",
        routes: [
          // {
          //     name: "Rewards",
          //     path: "/loyalty",
          //     icon: "mdi-run"
          // },
          {
            name: "Promo Codes",
            path: "/loyalty/promo-codes",
            icon: "mdi-cash-multiple",
          },
          // {
          //     name: "Experiences",
          //     path: "/loyalty/experiences",
          //     icon: "mdi-gift-open-outline"
          // },
          // {
          //   name: "Settings",
          //   path: "/loyalty/settings",
          //   icon: "mdi-cog-outline"
          // }
        ],
      },
      {
        name: "Donations",
        icon: "mdi-charity",
        color: "velocityBlue",
        viewable: true,
        path: "/donations",
        routes: [
          {
            name: "Products",
            path: "/store/products",
            icon: "mdi-hand-coin",
          },
        ],
      },
      // {
      //   name: "Parking",
      //   icon: "mdi-parking",
      //   color: "velocityBlue",
      //   viewable: false,
      //   path: "/parking",
      //   routes: [
      //     {
      //       name: "Overview",
      //       path: "/parking",
      //       icon: "mdi-lightning-bolt-outline",
      //     },
      //     {
      //       name: "My Locations",
      //       path: "/parking/locations",
      //       icon: "mdi-archive-marker-outline",
      //     },
      //     {
      //       name: "Settings",
      //       path: "/parking/settings",
      //       icon: "mdi-cog-outline",
      //     },
      //   ],
      // },

      // {
      //   name: "Marketing",
      //   icon: "mdi-bullhorn",
      //   color: "velocityBlue",
      //   viewable: false,
      //   path: "/marketing",
      //   routes: [
      //     {
      //       name: "Campaigns",
      //       path: "/marketing/campaigns",
      //       icon: "mdi-human-male-board-poll",
      //     },
      //     {
      //       name: "Impressions",
      //       path: "/marketing/impressions",
      //       icon: "mdi-account-eye-outline",
      //     },
      //     {
      //       name: "Settings",
      //       path: "/marketing/settings",
      //       icon: "mdi-cog-outline",
      //     },
      //   ],
      // },
      // {
      //   name: "Lodging",
      //   icon: "mdi-bed",
      //   color: "velocityBlue",
      //   viewable: false,
      //   path: "/lodging",
      //   routes: [
      //     {
      //       name: "Room Management",
      //       path: "/lodging/room_management",
      //       icon: "mdi-sofa-outline",
      //     },
      //     {
      //       name: "Housekeeping",
      //       path: "/lodging/housekeeping",
      //       icon: "mdi-room-service-outline",
      //     },
      //     {
      //       name: "Settings",
      //       path: "/lodging/settings",
      //       icon: "mdi-cog-outline",
      //     },
      //   ],
      // },
      // {
      //   name: "Dining",
      //   icon: "mdi-table-chair",
      //   color: "velocityBlue",
      //   viewable: false,
      //   path: "/dining",
      //   routes: [
      //     {
      //       name: "Restaraunts",
      //       path: "/dining/restaraunts",
      //       icon: "mdi-store-marker-outline",
      //     },
      //     {
      //       name: "Inventory",
      //       path: "/dining/inventory",
      //       icon: "mdi-silverware",
      //     },
      //     {
      //       name: "Settings",
      //       path: "/dining/settings",
      //       icon: "mdi-cog-outline",
      //     },
      //   ],
      // },
      // {
      //   name: "Attractions",
      //   icon: "mdi-ferris-wheel",
      //   color: "velocityBlue",
      //   viewable: false,
      //   path: "/attractions",
      //   routes: [
      //     {
      //       name: "Dashboard",
      //       path: "/attractions/dashboard",
      //       icon: "mdi-candy-outline",
      //     },
      //     {
      //       name: "Maintenance",
      //       path: "/attractions/maintenance",
      //       icon: "mdi-wrench-clock",
      //     },
      //     {
      //       name: "Settings",
      //       path: "/attractions/settings",
      //       icon: "mdi-cog-outline",
      //     },
      //   ],
      // },
    ],
    selectedApp: {},
  },
  getters: {
    apps: (state) => state.apps,
    selectedApp: (state) => state.selectedApp,
  },
  mutations: {
    [types.ROUTING.SET_SELECTED_APP](state, appName) {
      let appIndex = state.apps.findIndex((x) => x.name == appName);
      if (appIndex != -1) {
        state.selectedApp = state.apps[appIndex];
      }
    },
    [types.ROUTING.SET_APP_VIEWABILITY](state, appName) {
      let appIndex = state.apps.findIndex((x) => x.name == appName);
      if (appIndex != -1) {
        state.apps[appIndex].viewable = true;
      }
    },
  },
  actions: {
    setSelectedApp(ctx, appName) {
      ctx.commit(types.ROUTING.SET_SELECTED_APP, appName);
    },
    setSelectedAppByExistingRoute(ctx, route) {
      let index = -1;
      index = ctx.state.apps.findIndex((x) => route.path.includes(x.path));

      if (index != -1) {
        ctx.commit(types.ROUTING.SET_SELECTED_APP, ctx.state.apps[index].name);
      } else {
        ctx.commit(types.ROUTING.SET_SELECTED_APP, "Marketplace");
      }
    },
    setViewableApps(ctx) {
      if (ctx.getters.chiefedOrgs.length > 0) {
        //Temporarily just add all apps if they are chiefed to any org. Eventually will be permission based
        ctx.commit(types.ROUTING.SET_APP_VIEWABILITY, "Ticketing");
        ctx.commit(types.ROUTING.SET_APP_VIEWABILITY, "Overview");
        ctx.commit(types.ROUTING.SET_APP_VIEWABILITY, "Parking");
        ctx.commit(types.ROUTING.SET_APP_VIEWABILITY, "Rewards");
        ctx.commit(types.ROUTING.SET_APP_VIEWABILITY, "Marketing");
        ctx.commit(types.ROUTING.SET_APP_VIEWABILITY, "Lodging");
        ctx.commit(types.ROUTING.SET_APP_VIEWABILITY, "Dining");
        ctx.commit(types.ROUTING.SET_APP_VIEWABILITY, "Analytics");
        ctx.commit(types.ROUTING.SET_APP_VIEWABILITY, "Attractions");
      }
    },
    setAdminAccess(ctx) {
      if (ctx.getters.vAdmin === true) {
        ctx.commit(types.ROUTING.SET_APP_VIEWABILITY, "VAC");
      }
    },
  },
};
