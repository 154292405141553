<template>
  <div>
    <v-flex @click.stop="editTicketType">
      <v-flex d-flex>
        <v-flex xs5 sm5 md5 lg5 class="d-flex flex-column justify-center">
          <span class="spanBoldSmall mb-5">
            {{ truncate(product.name) }}
          </span>
          <span class="textParagraph1">
            {{ product.quantity == -1 ? "Unlimited" : product.quantity }}
            Available
          </span>
        </v-flex>

        <v-flex class="d-flex flex-column">
          <v-flex class="d-flex justify-end">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="`${product.active ? 'blue' : ''}`"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-shopping-outline
                </v-icon>
              </template>
              {{ product.active ? "Purchaseable" : "Not Purchaseable" }}
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mx-2"
                  :color="`${product.accessible ? 'blue' : ''}`"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-wheelchair</v-icon
                >
              </template>
              {{
                product.accessible
                  ? "Is Handicap Accessible"
                  : "Not Handicap Accessible"
              }}
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  :color="`${product.tax_exempt ? 'blue' : ''}`"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{
                    product.tax_exempt
                      ? "mdi-currency-usd"
                      : "mdi-currency-usd-off"
                  }}</v-icon
                >
              </template>
              {{ product.tax_exempt ? "Is Tax Exempt" : "Is Taxable" }}
            </v-tooltip>

            <v-btn class="mt-1" icon @click.stop="confirmDelete">
              <v-icon> mdi-trash-can-outline </v-icon>
            </v-btn>
          </v-flex>
          <v-flex d-flex justify-end
            ><span class="spanBoldMedium mt-2">
              {{ calculatePricing(product) }}
            </span></v-flex
          >
        </v-flex>
      </v-flex>
    </v-flex>
    <TicketTypeBuilder
      v-if="editingProduct"
      :product="product"
      @closeEditDialog="closeEditDialog"
      @saveProduct="saveProduct"
      @deleteProduct="deleteProduct"
    />
  </div>
</template>

<script>
import TicketTypeBuilder from "./TicketTypeBuilder.vue";
import { truncateString } from "../../utility/helperFunctions";
export default {
  props: { product: Object, products: Array, forceOpenEdit: Boolean },
  components: { TicketTypeBuilder },
  name: "TicketType",
  data: () => ({
    editingProduct: false,
    randomTicketTypes: [
      { name: "General Admission", quantity: "100", price: "10.25" },
      { name: "Box Seating", quantity: "55", price: "15.65" },
      { name: "VIP Admission", quantity: "25", price: "100.05" },
      { name: "Backstage Access", quantity: "10", price: "60.00" },
      { name: "Accessible Seating", quantity: "25", price: "21.55" },
    ],
  }),
  created() {
    if (this.product.placeholder || this.forceOpenEdit) {
      this.editingProduct = true;
    }
  },
  methods: {
    calculateQuantity() {},
    truncate(string) {
      if (string) return truncateString(string, 30);
    },
    calculatePricing(product) {
      if (product.pricing && product.pricing.length > 0) {
        const activePrices = product.pricing.filter((item) => item.active);
        let span = "";
        if (activePrices.length === 1) {
          span = `$${activePrices[0].price ?? ""}`;
        } else if (activePrices.length > 0) {
          const minValue = Math.min(...activePrices.map((item) => item.price));
          const maxValue = Math.max(...activePrices.map((item) => item.price));
          if (minValue == maxValue) {
            span = `$${minValue}`;
          } else {
            span = `$${minValue} - $${maxValue}`;
          }
        }

        if (span != "$") {
          return `${span}`;
        } else {
          return "";
        }
      }
      return "";
    },
    confirmDelete() {
      let confirmation = confirm(
        "Are you sure you want to delete this Ticket Type?"
      );
      if (confirmation) {
        this.deleteProduct(confirmation);
      }
    },
    deleteProduct(confirmed) {
      let productIndex = this.products.findIndex(
        (x) => x.id === this.product.id
      );
      if (productIndex != -1) {
        if (confirmed) {
          vDo(
            "product/delete_product",
            { product_id: this.product.id },
            (response) => {
              if (response["x"] == 200) {
                if (this.$route.params.eventID) {
                  this.$store.dispatch(
                    "loadEventProducts",
                    this.$route.params.eventID
                  );
                }
              } else {
                if (this.products[productIndex]["placeholder"]) {
                  //if it has placeholder object, it is not saved to DB yet.
                  this.products.splice(productIndex, 1);
                }

                this.$store.dispatch("setSnackBarMessage", {
                  message: response.data,
                  type: "FAILURE",
                });
              }
            }
          );
        }
      }
      this.closeEditDialog();
    },
    saveProduct() {
      this.products[this.productIndex] = this.product;
      console.log("ERROR");
      console.log(this.products);
      this.$store.dispatch("setModifiedEventProducts", this.products); //this line is breaking stuff
      this.closeEditDialog();
    },
    editTicketType() {
      this.editingProduct = true;
    },
    closeEditDialog() {
      this.editingProduct = false;
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    eventProducts() {
      return this.$store.getters.eventProducts;
    },
  },
  watch: {
    eventProducts(newVal) {
      if (newVal) {
        this.products = newVal;
      }
    },
    product(newVal) {
      if (this.forceOpenEdit) {
        this.editingProduct = true;
      }
    },
  },
};
</script>

<style scoped></style>
