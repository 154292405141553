<template>
  <div>
    <div>
      <a
        class="spanBoldSmall"
        @click="openAddresses"
        v-if="!selectedAddress.id"
      >
        Add {{ label }} Address
      </a>
      <div v-else>
        <label class="text-body-2">{{ capitalizeFirst(label) }} Address</label>
        <AddressComponent :address="selectedAddress['address']" :small="true" />
        <a class="spanBoldSmall" @click="openAddresses()">
          Edit {{ label }} Address
        </a>
      </div>
    </div>
    <div v-if="showAddresses">
      <v-dialog
        style="border-radius: 20px; top: 64px !important"
        width="500"
        transition="dialog-bottom-transition"
        v-model="showAddresses"
        persistent
      >
        <v-card v-if="addresses.length > 0 && !editAddress">
          <v-card-title>
            <span> Select a {{ capitalizeFirst(label) }} Address </span>
            <v-spacer />
            <v-btn icon @click="showAddresses = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <AddressComponent
            style="cursor: pointer"
            @click.native="setSelectedAddress(address)"
            v-for="address in addresses"
            :key="address.id"
            :address="address['address']"
            :defaultAddr="address['default']"
          />
          <v-footer>
            <v-btn
              class="velocityBlue white--text"
              block
              @click="editAddress = true"
            >
              Add a New Address
            </v-btn>
          </v-footer>
        </v-card>

        <v-card v-else-if="editAddress">
          <v-card-title>
            <span> Add a new Address </span>
            <v-spacer />
            <v-btn icon @click="editingAddress(false)">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <EditAddress :tribe="tribe" @editingAddress="editingAddress" />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import EditAddress from "@/components/addresses/EditAddress.vue";
import AddressComponent from "./AddressComponent.vue";
import { capitalizeFirstLetter } from "@/utility/helperFunctions";
export default {
  props: {
    tribe: String,
    label: String,
    address: Object,
  },
  components: { EditAddress, AddressComponent },
  name: "Addresses",
  data: () => ({
    showAddresses: false,
    editAddress: false,
    selectedAddressToEdit: "new",
  }),
  created() {
    if (!this.tribe) {
      this.$store.dispatch("loadAddresses");
    }
  },
  methods: {
    capitalizeFirst(string) {
      return capitalizeFirstLetter(string);
    },
    setSelectedAddress(address, hideAddresses = true) {
      let addressObj = {
        tribe: this.tribe,
        address: address,
      };
      this.$store.dispatch("setSelectedAddress", addressObj);
      if (hideAddresses) {
        this.showAddresses = false;
      }
    },
    editingAddress(editing) {
      this.editAddress = editing;
      if (this.addresses.length == 0) {
        this.showAddresses = false;
      }
    },
    openAddresses() {
      this.showAddresses = true;
      if (this.addresses.length == 0) {
        this.editAddress = true;
      }
    },
  },
  computed: {
    addresses() {
      return this.$store.getters.addresses;
    },
    selectedAddress() {
      return this.$store.getters.selectedAddress;
    },
  },
  watch: {
    addresses(newVal) {
      if (newVal) {
        if (newVal.length == 0) {
          this.editAddress = true;
        } else {
          this.editAddress = false;
        }

        if (newVal.length > 0) {
          let index = null;
          if (this.address) {
            index = newVal.findIndex((x) => x.id == this.address.id);
          } else {
            index = newVal.findIndex((x) => x.default == "true");
          }
          if (index != -1) {
            this.setSelectedAddress(newVal[index], false);
          }
        }
      }
    },
    tribe: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.$store.dispatch("loadAddresses", newVal);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
