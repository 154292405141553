import * as types from "../mutation-types";
export default {
  state: {
    cart: {},
    cartCount: 0,
    cartPrices: {},
    holdToken: {},
  },
  getters: {
    cartPrices: (state) => state.cartPrices,
    holdToken: (state) => state.holdToken,
    cart: (state) => state.cart,
    cartCount: (state) => state.cartCount,
  },
  mutations: {
    [types.CART.SET_CART_PRICES](state, cartPrices) {
      state.cartPrices = cartPrices;
    },
    [types.CART.SET_CART_COUNT](state, cartCount) {
      state.cartCount = cartCount;
    },
    [types.CART.SET_HOLD_TOKEN](state, holdToken) {
      state.holdToken = holdToken;
    },
    [types.CART.SET_USER_CART](state, cart) {
      state.cart = cart;
      if (cart && cart.line_items) {
        let count = 0;
        for (var i = 0; i < cart.line_items.length; i++) {
          count += Number(cart.line_items[i].quantity);
        }
        state.cartCount = count;
      } else {
        state.cartCount = 0;
      }
    },
  },
  actions: {
    clearCart(ctx, showSessionExpired = false) {
      //TODO:
      //If user has seatsio seats, we need to clear the token
      if (showSessionExpired) {
        ctx.dispatch("setSnackBarMessage", {
          message: "Your session has expired.",
          type: "FAILURE",
        });
      }
      vDo("payment/clear_cart", {}, (response) => {
        let user = response["data"];
        let cart = user.meta ? user.meta.cart || {} : {};
        if (Object.keys(cart).length > 0) {
          ctx.commit(types.CART.SET_USER_CART, cart);
        } else {
          ctx.commit(types.CART.SET_USER_CART, {});
        }
        ctx.commit(types.CART.SET_CART_PRICES, 0);
        ctx.commit(types.CART.SET_CART_COUNT, 0);
      });
    },
    fetchCartPrices(ctx) {
      ctx.state.networkLoading = true;
      vDo("payment/cart_price", { uid: vG("uid") }, (response) => {
        if (response.data.error_message) {
          ctx.dispatch("setSnackBarMessage", {
            message: response.data.error_message,
            type: "FAILURE",
          });
        } else if (response.data.warning_message) {
          ctx.dispatch("setSnackBarMessage", {
            message: response.data.warning_message,
            type: "FAILURE",
          });
        }

        ctx.commit(types.CART.SET_CART_PRICES, response.data);
        ctx.state.networkLoading = false;
      });
    },
    adjustHoldToken(ctx, minutes) {
      vDo(
        "integration/seats/adjust_token",
        { minutes: minutes },
        (response) => {
          if (response.x == 200) {
            ctx.dispatch("loadHoldToken");
          }
        }
      );
    },
    expireHoldToken(ctx, showSessionExpired = true) {
      ctx.dispatch("adjustHoldToken", 0);
      if (showSessionExpired) {
        ctx.dispatch("setSnackBarMessage", {
          message: "Your session has expired.",
          type: "FAILURE",
        });
      }
    },
    loadHoldToken(ctx, tokenObj) {
      // This endpoint either returns an existing token, or creates one and returns it
      vDo("hold_token/create_or_get_token", tokenObj, (response) => {
        if (response.x == 200) {
          if (response["data"]["new_token"] == true) {
            ctx.dispatch("clearCart");
          }
          ctx.commit(types.CART.SET_HOLD_TOKEN, response.data);
        } else {
          ctx.dispatch("clearCart");
        }
      });
    },
    // getHoldToken(ctx,) {
    //   vDo("/hold_token/get_token",
    //     {},
    //     (response) => {
    //       if (response.x == 200) {
    //         ctx.commit(types.CART.SET_HOLD_TOKEN, response.data);
    //       } else {
    //         ctx.dispatch("clearCart");
    //       }
    //     }
    //   );
    // },
  },
};
