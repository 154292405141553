
<div>
  <div>
    <a
      class="spanBoldSmall"
      @click="openAddresses"
      v-if="!selectedAddress.id"
    >
      Add {{ label }} Address
    </a>
    <div v-else>
      <label class="text-body-2">{{ capitalizeFirst(label) }} Address</label>
      <AddressComponent :address="selectedAddress['address']" :small="true" />
      <a class="spanBoldSmall" @click="openAddresses()">
        Edit {{ label }} Address
      </a>
    </div>
  </div>
  <div v-if="showAddresses">
    <v-dialog
      style="border-radius: 20px; top: 64px !important"
      width="500"
      transition="dialog-bottom-transition"
      v-model="showAddresses"
      persistent
    >
      <v-card v-if="addresses.length > 0 && !editAddress">
        <v-card-title>
          <span> Select a {{ capitalizeFirst(label) }} Address </span>
          <v-spacer />
          <v-btn icon @click="showAddresses = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <AddressComponent
          style="cursor: pointer"
          @click.native="setSelectedAddress(address)"
          v-for="address in addresses"
          :key="address.id"
          :address="address['address']"
          :defaultAddr="address['default']"
        />
        <v-footer>
          <v-btn
            class="velocityBlue white--text"
            block
            @click="editAddress = true"
          >
            Add a New Address
          </v-btn>
        </v-footer>
      </v-card>

      <v-card v-else-if="editAddress">
        <v-card-title>
          <span> Add a new Address </span>
          <v-spacer />
          <v-btn icon @click="editingAddress(false)">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <EditAddress :tribe="tribe" @editingAddress="editingAddress" />
      </v-card>
    </v-dialog>
  </div>
</div>
