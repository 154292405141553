
<div>
  <div v-if="timeslots.length > 0">
    <v-row
      v-for="(timeSlot, index) in timeslots"
      :key="index"
      class="align-center fullBorder rounded mb-2 pa-3 justify-space-between"
      no-gutters
    >
      <v-col cols="12" lg="11" md="11" sm="11">
        <v-row no-gutters>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="12"
            :class="isMobile ? '' : 'pr-1'"
          >
            <span class="spanSmallGrey"> Start Time </span>
            <v-text-field
              v-model="timeSlot.startTime"
              color="velocityBlue"
              dense
              hide-details
              label="Start Time"
              outlined
              append-icon=""
              placeholder="12:00"
              single-line
              type="time"
              @click:clear="timeSlot[index].startTime = null"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <span class="spanSmallGrey"> End Time </span>
            <v-text-field
              v-model="timeSlot.endTime"
              color="velocityBlue"
              dense
              hide-details
              label="End Time"
              append-icon=""
              outlined
              placeholder="12:00"
              single-line
              type="time"
              @click:clear="timeSlot[index].endTime = null"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="1" md="1" sm="1">
        <v-btn
          color="error"
          elevation="0"
          text
          x-small
          @click="$emit('removeTimeSlot', index)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>

  <v-btn
    :class="timeslots.length > 0 ? 'mt-4' : ''"
    class="white--text"
    color="velocityBlue"
    elevation="0"
    rounded
    small
    @click="$emit('addTimeSlot')"
  >
    Add Time Slot
  </v-btn>
</div>
