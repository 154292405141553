<template>
  <v-dialog
    id="mobileDialog"
    v-model="mobileDialog"
    fullscreen
    transition="dialog-bottom-transition"
    content-class="elevation-0"
    persistent
  >
    <v-card style="overflow-y: scroll">
      <v-list class="mb-15">
        <div class="px-15 pt-10 pb-5">
          <span class="spanBoldMedium" style="font-weight: bold">
            General
          </span>
        </div>
        <v-list-item @click="routeTo('profile')">
          <v-list-item-content class="text-left px-15 bottomBorder"
            ><v-flex d-flex>
              <v-list-item-icon>
                <v-icon class="pr-2">mdi-account-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title style="font-size: 1em"
                >Profile</v-list-item-title
              >
            </v-flex>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="hasOrg"
          v-for="item in overviewApp.routes"
          :key="item.name"
          link
        >
          <v-list-item-content
            @click="routeTo(item)"
            class="text-left px-15 bottomBorder"
          >
            <v-flex d-flex>
              <v-list-item-icon>
                <v-icon
                  class="pr-2"
                  :style="`${
                    selectedApp.name == item.name
                      ? `color: ${theme.velocityBlue}`
                      : ''
                  }`"
                  >{{ item.icon }}</v-icon
                >
              </v-list-item-icon>
              <v-list-item-title
                :style="`${
                  selectedApp.name == item.name
                    ? `color: ${theme.velocityBlue}; font-size: 1em;`
                    : 'font-size: 1em;'
                }`"
                >{{ item.name }}</v-list-item-title
              >
            </v-flex>
          </v-list-item-content>
        </v-list-item>

        <div class="px-15 pt-10 pb-5">
          <span
            v-if="selectedApp.name != 'Overview'"
            class="spanBoldMedium"
            style="font-weight: bold"
          >
            {{ selectedApp["name"] }}</span
          >
        </div>
        <v-list-item
          v-if="selectedApp.name != 'Overview'"
          v-for="item in selectedApp.routes"
          :key="item.name"
          link
        >
          <v-list-item-content
            @click="routeTo(item)"
            class="text-left px-15 bottomBorder"
          >
            <v-flex d-flex>
              <v-list-item-icon>
                <v-icon
                  class="pr-2"
                  :style="`${
                    selectedApp.name == item.name
                      ? `color: ${theme.velocityBlue}`
                      : ''
                  }`"
                  >{{ item.icon }}</v-icon
                >
              </v-list-item-icon>
              <v-list-item-title
                :style="`${
                  selectedApp.name == item.name
                    ? `color: ${theme.velocityBlue}; font-size: 1em;`
                    : 'font-size: 1em;'
                }`"
                >{{ item.name }}</v-list-item-title
              >
            </v-flex>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      setTopPadding: null,
    };
  },
  mounted() {
    this.setPadding();
  },
  updated() {
    this.setPadding();
  },
  beforeCreate() {
    document.getElementsByTagName("body")[0].className = "noscroll";
  },
  beforeDestroy() {
    document.body.removeAttribute("class", "noscroll");
  },
  methods: {
    setPadding() {
      if (!this.setTopPadding) {
        let dialog = document.getElementsByClassName("v-dialog");
        for (var i = 0; i < dialog.length; i++) {
          this.setTopPadding = document.getElementById("appbar").clientHeight;
          dialog[i].style["top"] = `${this.setTopPadding}px`;
        }
      }
    },
    routeHome() {
      this.$router.push({ path: "/" });
    },
    routeTo(route) {
      this.$emit("routeTo", route);
    },
    signOut() {
      vSignOut();
    },
  },
  computed: {
    selectedApp() {
      return this.$store.getters.selectedApp;
    },
    overviewApp() {
      let overviewIndex = this.$store.getters.apps.findIndex(
        (x) => x.name == "Overview"
      );
      if (overviewIndex != -1) {
        return this.$store.getters.apps[overviewIndex];
      }
    },
    userTribe() {
      return this.$store.getters.userTribe;
    },
    theme() {
      return this.$vuetify.theme.defaults.light;
    },
    hasOrg() {
      return this.$store.getters.hasOrg;
    },
  },
  watch: {},
  props: {
    mobileDialog: Boolean,
  },
};
</script>

<style scoped>
.v-dialog--fullscreen {
  top: 50px;
}
.noscroll {
  position: fixed;
  overflow-x: hidden;
  overflow-y: hidden;
}
.v-overlay--active {
  touch-action: none;
}
</style>
