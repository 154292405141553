import * as types from "../mutation-types";

export default {
  state: {
    promoCodes: [],
    promoCode: {},
    promoCodeSchema: {},
  },
  getters: {
    promoCodes: (state) => state.promoCodes,
    promoCode: (state) => state.promoCode,
    promoCodeSchema: (state) => state.promoCodeSchema,
  },
  mutations: {
    [types.LOYALTY.SET_PROMO_CODES](state, promoCodes) {
      state.promoCodes = promoCodes;
    },
    [types.LOYALTY.SET_PROMO_CODE](state, promoCode) {
      promoCode["active"] = promoCode["active"] == "true" ? true : false;
      state.promoCode = promoCode;
    },
    [types.LOYALTY.SET_PROMO_CODE_SCHEMA](state, promoCodeSchema) {
      state.promoCodeSchema = promoCodeSchema;
    },
  },
  actions: {
    loadPromoCodes(ctx) {
      let appContextOrg = ctx.getters.appContextOrg;
      if (appContextOrg.id) {
        vDo(
          "promotions/get_promo_codes",
          { org_id: appContextOrg.id },
          (response) => {
            ctx.commit(types.LOYALTY.SET_PROMO_CODES, response["data"]);
          }
        );
      }
    },
    loadPromoCode(ctx, promoCodeID) {
      vDo(
        "promotions/get_promo_code",
        { promo_code_id: promoCodeID },
        (response) => {
          ctx.commit(types.LOYALTY.SET_PROMO_CODE, response["data"]);
        }
      );
    },
    loadNewPromoCode(ctx) {
      if (Object.keys(ctx.getters.promoCodeSchema).length == 0) {
        vDo("promotions/get_promo_code_schema", {}, (response) => {
          ctx.commit(types.LOYALTY.SET_PROMO_CODE_SCHEMA, response["data"]);
        });
      }
    },
  },
};
