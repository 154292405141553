
<div>
  <v-btn class="velocityBlue white--text" @click="creatingEvent = true">
    <span class="textParagraph1 font-weight-bold">Create an Event</span>
    <v-icon class="ml-2" small> mdi-arrow-right </v-icon>
  </v-btn>
  <EventBuilderDraft
    v-if="creatingEvent"
    :creatingEvent="creatingEvent"
    @closeEvent="closeEvent"
  />
</div>
