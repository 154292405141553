
<div>
  <v-autocomplete
    v-model="selectedPlace"
    :items="placesResults"
    :search-input.sync="placesSearch"
    clearable
    dense
    solo
    item-text="description"
    item-value="place_id"
    return-object
    :label="label ? label : 'Address'"
    prepend-inner-icon="mdi-map-marker-outline"
    no-filter
    hide-no-data
    @change="updatePlace()"
    color="velocityBlue"
  ></v-autocomplete>
</div>
