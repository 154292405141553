
<div>
  <v-menu bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        outlined
        elevation="0"
        :class="`${
          miniVariant ? 'transparent ma-0 pa-0' : 'transparent'
        } mt-2 d-flex align-center`"
      >
        <span v-if="!miniVariant" style="max-width: 32px" class="mr-2">
          <v-img :src="meta.logo_image" />
        </span>
        <span class="paragraph1">{{
          miniVariant ? firstAndLastChar.toUpperCase() : truncate(orgName)
        }}</span>
        <v-icon v-if="chiefedOrgs.length > 1" :small="miniVariant">
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title v-if="chiefedOrgs.length > 1" class="ma-0 pa-0 pa-4">
        <span class="textSubtitle">Switch Organization</span>
      </v-card-title>
      <v-divider />
      <v-container class="org-list-container">
        <v-row
          v-for="org in chiefedOrgs"
          :key="org.id"
          @click="switchAppOrg(org)"
          class="bottomBorder px-3 py-2 ma-0"
          align="center"
          style="cursor: pointer"
        >
          <v-col cols="3">
            <v-avatar
              style="border-radius: 10px !important"
              color="velocityBlue"
              class="white--text"
              size="40"
              tile
            >
              {{ getChiefedOrgName(org)[0] }}
            </v-avatar>
          </v-col>
          <v-col cols="9">
            <v-flex class="d-flex flex-column">
              <span class="textParagraph1 font-weight-bold">
                {{ getChiefedOrgName(org) }}
              </span>
              <span v-if="org.email" class="textCaption" color="grey">
                {{ org.email }}
              </span>
            </v-flex>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-menu>
</div>
