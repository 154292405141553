
<div class="mt-4 mb-2">
  <span class="spanSmallGrey">
    {{ `Venue Seating Type` }}
  </span>
  <v-radio-group
    :disabled="eventProducts.length > 0 || seatingChartSelected"
    class="ma-0 pa-0 mt-4"
    v-model="seatingType"
    row
  >
    <v-radio
      color="velocityBlue"
      label="General Admission"
      value="general_admission"
    ></v-radio>
    <v-radio
      color="velocityBlue"
      label="Assigned Seating"
      value="assigned_seating"
    ></v-radio>
    <!-- <v-radio color="velocityBlue" label="Parking" value="parking"></v-radio> -->
  </v-radio-group>
  <div v-if="seatingType == 'general_admission'">
    <TicketTypes />
  </div>
  <SeatsChartSelector
    v-if="seatingType == 'assigned_seating'"
    @setSelectedChart="setMeta"
    :meta="meta"
  />
</div>
