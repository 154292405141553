<template>
  <div>
    <v-flex
      v-for="(product, index) in products"
      :key="index + product.id ? product.id : '-id'"
      :class="`d-flex flex-column pa-2 px-4 mb-2 fullBorder`"
      style="cursor: pointer"
    >
      <TicketType :product="product" :products="products" />
    </v-flex>
    <a class="spanBoldSmall" style="cursor: pointer" @click="addNewProduct">
      {{ `+ Add Ticket Type` }}
    </a>
  </div>
</template>

<script>
import TicketType from "./TicketType.vue";
export default {
  props: {},
  components: { TicketType },
  name: "TicketTypes",
  data: () => ({
    randomTicketTypes: [
      { name: "General Admission", quantity: "100", price: "10.25" },
      { name: "Box Seating", quantity: "55", price: "15.65" },
      { name: "VIP Admission", quantity: "25", price: "100.05" },
      { name: "Backstage Access", quantity: "10", price: "60.00" },
      { name: "Accessible Seating", quantity: "25", price: "21.55" },
    ],
    products: [],
  }),
  created() {
    this.products = this.eventProducts;
  },
  methods: {
    addNewProduct() {
      const productWithPlaceholder = { ...this.newProductSchema };
      productWithPlaceholder["id"] = vGUID();
      productWithPlaceholder["placeholder"] = this.getRandomTicketType();
      this.products.push(productWithPlaceholder);
    },
    getRandomTicketType() {
      const randomIndex = Math.floor(
        Math.random() * this.randomTicketTypes.length
      );

      return this.randomTicketTypes[randomIndex];
    },
  },
  computed: {
    eventProducts() {
      return this.$store.getters.eventProducts;
    },
    newProductSchema() {
      return this.$store.getters.newProductSchema;
    },
  },
  watch: {
    eventProducts(newVal) {
      if (newVal) {
        this.products = newVal;
      }
    },
  },
};
</script>

<style scoped></style>
