
<v-autocomplete
  :disabled="disabled"
  outlined
  dense
  v-model="selectedTimezone"
  :items="timezones"
  :label="label"
  item-text="name"
  item-value="timezone"
></v-autocomplete>
