import * as types from "../mutation-types";

export default {
  state: {
    snackbarMessage: "",
    isMobile: false,
    velocityLogo: "/velocity_icon.png",
    showLoadingIndicator: false,
  },
  getters: {
    snackbarMessage: (state) => state.snackbarMessage,
    isMobile: (state) => state.isMobile,
    velocityLogo: (state) => state.velocityLogo,
    showLoadingIndicator: (state) => state.showLoadingIndicator,
  },
  mutations: {
    [types.COMMON.SET_SNACKBAR_MESSAGE](state, message) {
      state.snackbarMessage = message;
    },
    [types.COMMON.SET_IS_MOBILE](state, isMobile) {
      state.isMobile = isMobile;
    },
    [types.COMMON.SET_LOADING](state, loading) {
      state.showLoadingIndicator = loading;
    },
  },
  actions: {
    setIsMobile(ctx, isMobile) {
      ctx.commit(types.COMMON.SET_IS_MOBILE, isMobile);
    },
    setSnackBarMessage(ctx, snackbarMessage) {
      ctx.commit(types.COMMON.SET_SNACKBAR_MESSAGE, snackbarMessage);
    },
    clearSnackBarMessage(ctx) {
      ctx.commit(types.COMMON.SET_SNACKBAR_MESSAGE, false);
    },
    setLoading(ctx, showLoadingIndicator) {
      ctx.commit(types.COMMON.SET_LOADING, showLoadingIndicator);
    },
  },
};
