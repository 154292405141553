<template>
  <v-app
    v-if="networkLoaded && !showLogin"
    id="app"
    style="background-color: #f9f9f9"
  >
    <div id="topEl" />
    <NavigationBar />
    <SideMenu v-if="showSidemenu && selectedApp.name != 'Marketplace'" />
    <SnackBar />
    <LoadingSpinner v-if="showLoadingIndicator" />
    <v-main :style="`padding-bottom: ${bodyPadding}px !important`">
      <router-view :key="$route.fullPath" />
    </v-main>
  </v-app>
  <v-app v-else-if="networkLoaded">
    <Login :showBackButton="true" />
  </v-app>
</template>

<script>
import NavigationBar from "./components/app/NavigationBar.vue";
import SnackBar from "./components/app/SnackBar.vue";
import SideMenu from "./components/app/SideMenu.vue";
import Login from "./views/app/Login.vue";
import LoadingSpinner from "./components/app/LoadingSpinner.vue";
export default {
  name: "App",
  components: {
    NavigationBar,
    SnackBar,
    SideMenu,
    Login,
    LoadingSpinner,
  },
  data: () => ({
    mobileWidth: 960,
    networkLoaded: false,
    timeout: {},
    showSidemenu: false,
    loadedAppOrg: false,
    bodyPadding: 25,
    adjustedForFooter: false,
    initialRouteLoaded: false,
  }),
  created() {
    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
  },
  mounted() {
    this.checkIfNetworkLoaded();
    this.setBodyPadding();
  },
  updated() {
    // Set top padding of dialog to slide under navigation bar
    this.setBodyPadding();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIfMobile);
  },
  methods: {
    setBodyPadding() {
      if (!this.adjustedForFooter) {
        let mobileFooter = document.getElementById("mobileFooter");
        if (mobileFooter) {
          this.adjustedForFooter = true;
          this.bodyPadding = mobileFooter.clientHeight;
        }
      }
    },
    checkIfNetworkLoaded() {
      // Check if visyfy network has loaded or not
      window.vGwait = setInterval(() => {
        if (window.vGready) {
          clearInterval(window.vGwait);
          this.networkLoaded = true;
          if (vSID.length > 20) {
            this.login();
          }
        }
      }, 200);
    },
    login() {
      this.$store.dispatch("loadUser");
      // this.loadUser();
      this.$store.dispatch("loadChiefedOrgs");
      this.loggedIn = true;
    },
    checkIfMobile() {
      const isMobile = window.innerWidth < this.mobileWidth;
      this.$store.dispatch("setIsMobile", isMobile);
    },
    loadUser() {
      this.timeout = setTimeout(() => this.loadUser(), 500);
      let uid = vG("uid");
      if (this.userTribe.id != uid && uid) {
        this.$store.dispatch("loadUser");
        clearTimeout(this.timeout);
      }
    },
    loadAppContextOrg() {
      if (
        this.chiefedOrgs.length > 0 &&
        this.userTribe.id &&
        !this.appContextOrg.id &&
        !this.loadedAppOrg
      ) {
        this.loadedAppOrg = true;
        if (this.userTribe.meta["app_context_org"]) {
          this.$store.dispatch(
            "loadAppContextOrg",
            this.userTribe.meta["app_context_org"]
          );
        } else {
          this.$store.dispatch("loadAppContextOrg", this.chiefedOrgs[0]["id"]);
        }
      }
    },
    clearAppData() {
      this.$store.dispatch("clearOrgEvents");
    },
  },
  computed: {
    userTribe() {
      return this.$store.getters.userTribe;
    },
    showLogin() {
      return this.$store.getters.showLogin;
    },
    chiefedOrgs() {
      return this.$store.getters.chiefedOrgs;
    },
    vAdmin() {
      return this.$store.getters.vAdmin;
    },
    selectedApp() {
      return this.$store.getters.selectedApp;
    },
    appContextOrg() {
      return this.$store.getters.appContextOrg;
    },
    showLoadingIndicator() {
      return this.$store.getters.showLoadingIndicator;
    },
  },
  watch: {
    $route(to, from) {
      if (this.initialRouteLoaded == false) {
        this.$store.dispatch("setSelectedAppByExistingRoute", this.$route);
      }
      this.initialRouteLoaded = true;
    },
    appContextOrg(newVal, oldVal) {
      if (newVal.id != oldVal.id) {
        this.clearAppData();
        if (
          oldVal.id &&
          this.selectedApp.routes[0].path != "/marketplace/search"
        ) {
          this.$router.push({ path: this.selectedApp.routes[0].path });
        }
      }
      if (newVal) {
        this.$store.dispatch("fetchOrgWorkspacekey", newVal.id);
      }
    },
    userTribe(newVal) {
      if (newVal.id) {
        this.loadAppContextOrg();
      }
    },
    selectedApp(newVal, oldVal) {
      if (oldVal.routes && newVal.routes) {
        this.$router.push({ path: newVal.routes[0].path });
      }
    },
    chiefedOrgs(newVal) {
      if (newVal.length > 0) {
        this.loadAppContextOrg();
        this.$store.dispatch("setViewableApps");
        this.showSidemenu = true;
      } else {
        this.showSidemenu = false;
      }
    },
    vAdmin(newVal) {
      if (newVal == true) {
        this.$store.dispatch("setAdminAccess");
      }
    },
  },
};
</script>

<style lang="scss">
@import "./font.scss";
@import "./global.css";
#app {
  font-family: $body-font-family !important;
}
</style>
