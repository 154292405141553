import { DateTime } from "luxon";

export function phoneFormat(input) {
  //returns (###) ###-####
  input = input.replace(/\D/g, "");
  var size = input.length;
  if (size > 0) {
    input = "(" + input;
  }
  if (size > 3) {
    input = input.slice(0, 4) + ") " + input.slice(4, 11);
  }
  if (size > 6) {
    input = input.slice(0, 9) + "-" + input.slice(9);
  }
  return input;
}

export function validatePhoneNumber(phone) {
  var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  return re.test(phone);
}

export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

// Function to check if URL is valid
export function validateURL(string) {
  try {
    const url = new URL(string);
    // Check if the URL's protocol is either http: or https:
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (e) {
    return false; // URL is invalid
  }
}

// returns true if is exact copy
export function deepCompare(firstObj, secondObj) {
  let first = JSON.stringify(firstObj);
  let second = JSON.stringify(secondObj);
  return first == second;
}

export function currencyFormat(currencyType) {
  let type = currencyType || "USD";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: type,
  });
}

export function convertDateToISO(date) {
  if (date) {
    return date.toISOString();
  } else {
    return "";
  }
}

export function getCurrentDateInTimezone(timezone) {
  const nowInTimezone = DateTime.now().setZone(timezone);
  return nowInTimezone;
}

export function convertDateTimeToISOWithTimezone(date, time, timeZone) {
  const dateTime = DateTime.fromFormat(`${date} ${time}`, "yyyy-MM-dd HH:mm", {
    zone: timeZone,
  });
  return dateTime.toUTC().toISO();
}

export function convertUTCDateToTimezone(dateTimeString, timezone) {
  if (
    dateTimeString &&
    (typeof dateTimeString === "string" || dateTimeString instanceof String)
  ) {
    dateTimeString = dateTimeString.replace(" ", "T");

    return DateTime.fromISO(dateTimeString, { zone: "utc" }).setZone(timezone);
  } else {
    return "";
  }
}

export function dateFormat(date) {
  if (date) {
    return new Date(date).toLocaleString();
  } else {
    return "";
  }
}

export function dateFormatToHumanReadable(date, timezone) {
  if (date) {
    if ((!date) instanceof DateTime || (!date.isValid && timezone)) {
      date = convertUTCDateToTimezone(date, timezone);
      if (!date || (!date) instanceof DateTime || !date.isValid) {
        return "";
      }
    }

    let datetime = "";
    if (timezone) {
      // If a timeZone is provided, use it; otherwise, default to the system's local time zone
      datetime = DateTime.fromISO(date, { zone: timezone });

      // Format the date to a human-readable form
      let humanReadable = datetime.toLocaleString(
        DateTime.DATETIME_MED_WITH_WEEKDAY
      );
      return humanReadable;
    } else {
      date = new Date(date).toISOString();
      date = DateTime.fromISO(date);
      let humanReadable = date.toLocaleString(
        DateTime.DATETIME_MED_WITH_WEEKDAY
      );
      return humanReadable;
    }
  } else {
    return "";
  }
}

export function getHumanReadableTimezoneAbbreviation(timeZone) {
  if (timeZone) {
    var zone = new Date()
      .toLocaleTimeString("en-us", {
        timeZone: timeZone,
        timeZoneName: "short",
      })
      .split(" ")[2];
    return zone;
  } else {
    return "";
  }
}

export function dateFormatToHumanReadableDate(date) {
  if (date) {
    const luxonDate = DateTime.fromISO(new Date(date).toISOString());
    let humanReadable = luxonDate.toFormat("ccc, LLL dd, yyyy"); // Custom format
    return humanReadable;
  } else {
    return "";
  }
}

export function timeSpan(start, end) {
  let span = `${dateFormatToHumanReadable(start)} ${
    end ? ` - ${dateFormatToHumanReadable(end)}` : ""
  }`;
  return span;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function fetchImageFromCDN(tribe) {
  if (tribe.data) {
    return vCDN(tribe.data.media, "v");
  } else {
    return vCDN(tribe, "v");
  }
}

export function imageExporter(image, callback) {
  let type = image.type.replace("image/", "");
  let fr = new FileReader();
  fr.addEventListener("load", (e) => {
    // console.log(e.target.result);
    let jsonObject = {
      filename: image.name,
      attachment: e.target.result,
    };
    vDo("cdn/s3put", jsonObject, (response) => {
      callback(response);
    });
  });
  fr.readAsDataURL(image);
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function truncateString(str, length) {
  return str.length > length ? str.slice(0, length - 1) + "..." : str;
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export function onVisibilityChange(el, callback) {
  return function () {
    if (!el) {
      return;
    }
    callback(isElementInViewport(el));
  };
}

function isElementInViewport(el) {
  let bounding = el.getBoundingClientRect();
  let myElementHeight = el.offsetHeight;
  let myElementWidth = el.offsetWidth;

  if (
    bounding.top >= -myElementHeight &&
    bounding.left >= -myElementWidth &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth) +
        myElementWidth &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) +
        myElementHeight
  ) {
    return true;
  } else {
    return false;
  }
}

export function iLike(string, value) {
  return string.toLowerCase().includes(value.toLowerCase());
}

export function hasMoreThanTwoDecimals(num) {
  const fractionalPart = num.toString().split(".")[1];
  return fractionalPart && fractionalPart.length > 2;
}

export function betterToFixed(number, decimalCount = 2) {
  number = Number(number);
  if (hasMoreThanTwoDecimals(number)) {
    return number.toFixed(decimalCount);
  } else {
    return number;
  }
}

export function generateNeutralColor() {
  // Generate a random hue from 0 to 360
  const hue = Math.floor(Math.random() * 361);

  // Keep saturation low, between 0% and 50% for a wider range
  const saturation = Math.floor(Math.random() * 51);

  // Keep lightness between 20% and 80% for a wider range
  const lightness = Math.floor(Math.random() * 61) + 20;

  // Return the Hex of HSL color string
  return hslToHex(hue, saturation, lightness);
}

function hslToHex(h, s, l) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export function setMetaTag(attrName, attrValue, content) {
  let tag = document.querySelector(`meta[${attrName}="${attrValue}"]`);
  if (!tag) {
    tag = document.createElement("meta");
    tag.setAttribute(attrName, attrValue);
    document.getElementsByTagName("head")[0].appendChild(tag);
  }
  tag.setAttribute("content", content);
}
