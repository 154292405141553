// The store is for reactive data that will be utilized throughout the application.
// Consists of hepful values and network results that can be used in multiple components
import Vue from "vue";
import Vuex from "vuex";
import common from "./modules/common";
import apps from "./modules/apps";
import vAPI from "./modules/vAPI";
import event from "./modules/event";
import navigationEvents from "./modules/navigationEvents";
import product from "./modules/product";
import cart from "./modules/cart";
import loyalty from "./modules/loyalty";
import seatsIO from "./modules/seatsIO";
import store from "./modules/store";
import admin from "./modules/admin";
import stripe from "./modules/stripe";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  string: debug,
  modules: {
    common,
    apps,
    vAPI,
    event,
    navigationEvents,
    product,
    cart,
    loyalty,
    seatsIO,
    store,
    admin: admin,
    stripe,
  },
});
