
<div>
  <v-flex @click.stop="editTicketType">
    <v-flex d-flex>
      <v-flex xs5 sm5 md5 lg5 class="d-flex flex-column justify-center">
        <span class="spanBoldSmall mb-5">
          {{ truncate(product.name) }}
        </span>
        <span class="textParagraph1">
          {{ product.quantity == -1 ? "Unlimited" : product.quantity }}
          Available
        </span>
      </v-flex>

      <v-flex class="d-flex flex-column">
        <v-flex class="d-flex justify-end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="`${product.active ? 'blue' : ''}`"
                v-bind="attrs"
                v-on="on"
              >
                mdi-shopping-outline
              </v-icon>
            </template>
            {{ product.active ? "Purchaseable" : "Not Purchaseable" }}
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mx-2"
                :color="`${product.accessible ? 'blue' : ''}`"
                v-bind="attrs"
                v-on="on"
              >
                mdi-wheelchair</v-icon
              >
            </template>
            {{
              product.accessible
                ? "Is Handicap Accessible"
                : "Not Handicap Accessible"
            }}
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                :color="`${product.tax_exempt ? 'blue' : ''}`"
                v-bind="attrs"
                v-on="on"
              >
                {{
                  product.tax_exempt
                    ? "mdi-currency-usd"
                    : "mdi-currency-usd-off"
                }}</v-icon
              >
            </template>
            {{ product.tax_exempt ? "Is Tax Exempt" : "Is Taxable" }}
          </v-tooltip>

          <v-btn class="mt-1" icon @click.stop="confirmDelete">
            <v-icon> mdi-trash-can-outline </v-icon>
          </v-btn>
        </v-flex>
        <v-flex d-flex justify-end
          ><span class="spanBoldMedium mt-2">
            {{ calculatePricing(product) }}
          </span></v-flex
        >
      </v-flex>
    </v-flex>
  </v-flex>
  <TicketTypeBuilder
    v-if="editingProduct"
    :product="product"
    @closeEditDialog="closeEditDialog"
    @saveProduct="saveProduct"
    @deleteProduct="deleteProduct"
  />
</div>
