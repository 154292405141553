import * as types from "../mutation-types";

export default {
  state: {
    newPaymentReaderSchema: {},
    existingPaymentReader: {},
    paymentReaders: [],
    kioskPaymentReader: {},
    pollingCounter: 0,
    awaitingKiosk: false,
    paymentIntent: null,
  },
  getters: {
    existingPaymentReader: (state) => state.existingPaymentReader,
    paymentReaders: (state) => state.paymentReaders,
    newPaymentReaderSchema: (state) => state.newPaymentReaderSchema,
    kioskPaymentReader: (state) => state.kioskPaymentReader,
    pollingCounter: (state) => state.pollingCounter,
    awaitingKiosk: (state) => state.awaitingKiosk,
    paymentIntent: (state) => state.paymentIntent,
  },
  mutations: {
    [types.STRIPE.SET_PAYMENT_READER](state, existingPaymentReader) {
      state.existingPaymentReader = existingPaymentReader;
    },
    [types.STRIPE.SET_PAYMENT_READERS](state, paymentReaders) {
      state.paymentReaders = paymentReaders;
    },
    [types.STRIPE.SET_NEW_PAYMENT_READER_SCHEMA](
      state,
      newPaymentReaderSchema
    ) {
      state.newPaymentReaderSchema = newPaymentReaderSchema;
    },
    [types.STRIPE.SET_KIOSK_PAYMENT_READER](state, kioskPaymentReader) {
      state.kioskPaymentReader = kioskPaymentReader;
    },
    SET_POLLING_COUNTER(state, value) {
      state.pollingCounter = value;
    },
    INCREMENT_POLLING_COUNTER(state) {
      state.pollingCounter += 1;
    },
    SET_AWAITING_KIOSK(state, value) {
      state.awaitingKiosk = value;
    },
    [types.STRIPE.SET_PAYMENT_INTENT](state, paymentIntent) {
      state.paymentIntent = paymentIntent;
    },
  },
  actions: {
    clearKioskReader(ctx) {
      ctx.commit(types.STRIPE.SET_KIOSK_PAYMENT_READER, {});
    },
    setKioskReader(ctx, readerID) {
      let appContextOrg = ctx.getters.appContextOrg;
      if (appContextOrg.id) {
        vDo(
          "/integration/stripe/reader/get_reader",
          { reader_id: readerID },
          (response) => {
            if (response["x"] == 200) {
              ctx.commit(
                types.STRIPE.SET_KIOSK_PAYMENT_READER,
                response["data"]
              );
              ctx.dispatch("cancelKioskAction");
            }
          }
        );
      }
    },
    setAwaitingKiosk({ commit }, value) {
      commit("SET_AWAITING_KIOSK", value);
    },
    setPaymentIntent({ commit }, paymentIntent) {
      commit("SET_PAYMENT_INTENT", paymentIntent);
    },
    sendPaymentIntentToKiosk(ctx, paymentObject) {
      let paymentIntentID = paymentObject["payment_intent_id"];
      if (!ctx.getters.kioskPaymentReader["id"]) {
        return;
      }
      // ctx.dispatch("cancelKioskAction");
      ctx.dispatch("setAwaitingKiosk", true);
      vDo(
        "/integration/stripe/reader/payment_handler/process_payment",
        {
          terminal_id: ctx.getters.kioskPaymentReader["reader_id"],
          payment_intent_id: paymentIntentID,
        },
        (response) => {
          if (response["x"] == 200) {
            ctx.dispatch("updatePollingCounter", 0);
            ctx.dispatch("pollForPaymentIntentResponse", paymentObject);
          } else {
            ctx.dispatch("setSnackBarMessage", {
              message:
                "An error occurred sending payment to terminal. Please restart the terminal and try again.",
              type: "FAILURE",
            });
          }
        }
      );
    },
    pollForPaymentIntentResponse(ctx, paymentObject) {
      let txnObj = { payment_intent_id: paymentObject["payment_intent_id"] };
      vDo(
        "/integration/stripe/reader/payment_handler/get_gem_txn_from_payment_intent",
        txnObj,
        (response) => {
          if (response["x"] == 200) {
            ctx.dispatch("setAwaitingKiosk", false);
            paymentObject.callback(response);
            return;
          }
          if (response["x"] == 300 && ctx.getters.pollingCounter < 45) {
            setTimeout(() => {
              ctx.dispatch("incrementPollingCounter");
              ctx.dispatch("pollForPaymentIntentResponse", paymentObject);
            }, 3000);
          } else {
            ctx.dispatch("cancelKioskAction");
            ctx.dispatch("setAwaitingKiosk", false);
            paymentObject.callback(response);
          }
        }
      );
    },
    updatePollingCounter({ commit }, value) {
      commit("SET_POLLING_COUNTER", value);
    },
    incrementPollingCounter({ commit }) {
      commit("INCREMENT_POLLING_COUNTER");
    },
    cancelKioskAction(ctx) {
      if (!ctx.getters.kioskPaymentReader["id"]) {
        return;
      }

      vDo(
        "/integration/stripe/reader/payment_handler/cancel_terminal_action",
        {
          terminal_id: ctx.getters.kioskPaymentReader["reader_id"],
        },
        (response) => {
          ctx.dispatch("setAwaitingKiosk", false);
        }
      );
    },
    clearPaymentReader(ctx) {
      ctx.commit(types.STRIPE.SET_PAYMENT_READER, {});
    },
    loadNewPaymentReaderSchema(ctx) {
      vDo(
        "/integration/stripe/reader/get_new_reader_schema",
        {},
        (response) => {
          ctx.commit(types.STRIPE.SET_NEW_PAYMENT_READER_SCHEMA, response.data);
        }
      );
    },
    loadPaymentReadersForAdmin(ctx) {
      let appContextOrg = ctx.getters.appContextOrg;
      if (appContextOrg.id) {
        vDo(
          "/integration/stripe/reader/get_readers_for_admin",
          { org_id: appContextOrg.id },
          (response) => {
            if (response["x"] == 200) {
              ctx.commit(types.STRIPE.SET_PAYMENT_READERS, response["data"]);
            }
          }
        );
      }
    },
    loadPaymentReadersForOrg(ctx) {
      let appContextOrg = ctx.getters.appContextOrg;
      if (appContextOrg.id) {
        vDo(
          "/integration/stripe/reader/get_readers_for_org",
          { org_id: appContextOrg.id },
          (response) => {
            if (response["x"] == 200) {
              ctx.commit(types.STRIPE.SET_PAYMENT_READERS, response["data"]);
            }
          }
        );
      }
    },
    loadPaymentReader(ctx, readerID) {
      let appContextOrg = ctx.getters.appContextOrg;
      if (appContextOrg.id) {
        vDo(
          "/integration/stripe/reader/get_reader",
          { reader_id: readerID },
          (response) => {
            if (response["x"] == 200) {
              ctx.commit(types.STRIPE.SET_PAYMENT_READER, response["data"]);
            }
          }
        );
      }
    },
    createOrUpdateReader(ctx, readerObj) {
      let paymentReader = readerObj["payment_reader"];
      ctx.dispatch("setLoading", true);
      vDo(
        "/integration/stripe/reader/register_stripe_reader",
        { reader: paymentReader },
        (response) => {
          if (response["x"] == 200) {
            ctx.commit(types.STRIPE.SET_PAYMENT_READER, response["data"]);
            ctx.dispatch("setSnackBarMessage", {
              message: "Successfully saved Payment Reader",
              type: "SUCCESS",
            });
            if (readerObj.callback_func) {
              readerObj.callback_func(response["data"]["id"]);
            }
          } else {
            ctx.dispatch("setSnackBarMessage", {
              message: response["data"],
              type: "FAILURE",
            });
          }
          ctx.dispatch("setLoading", false);
        }
      );
    },
    removeReader(ctx, readerObj) {
      let readerID = readerObj["reader_id"];
      ctx.dispatch("setLoading", true);
      vDo(
        "/integration/stripe/reader/delete_reader",
        { reader_id: readerID },
        (response) => {
          ctx.dispatch("setLoading", false);
          if (response["x"] == 200) {
            ctx.dispatch("setSnackBarMessage", {
              message: "Successfully removed Payment Reader",
              type: "SUCCESS",
            });
            if (readerObj.callback_func) {
              readerObj.callback_func();
            }
          } else {
            ctx.dispatch("setSnackBarMessage", {
              message: response["data"],
              type: "FAILURE",
            });
          }
        }
      );
    },
  },
};
