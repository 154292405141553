<template>
  <div>
    <v-app-bar app min-height="60" class="white" id="appbar">
      <Hamburger
        ref="mobileHamburger"
        v-if="isMobile"
        :toggled="displayMobileNav"
        @toggleMobileNav="toggleMobileNav"
      />
      <v-img height="50px" width="120px" contain src="/u_of_u_logo.png" />
      <AppChoiceButton class="ma-0 pa-0" v-if="hasOrg && isMobile" />
      <ProfileButton v-if="!hasOrg && isMobile" />
      <v-container v-if="!isMobile">
        <v-row
          no-gutters
          style="flex-wrap: nowrap"
          class="d-flex align-content-right justify-right"
        >
          <v-spacer />
          <div
            v-for="item in selectedApp.routes"
            :key="item.name"
            v-if="selectedApp.name == 'Marketplace'"
          >
            <Cart v-if="item.name == 'My Cart'" />

            <v-btn v-else @click="routeTo(item)" text>
              <v-icon class="pr-2" medium> {{ item.icon }} </v-icon>
              {{ item.name }}
            </v-btn>
          </div>
          <NavigationItems
            v-for="item in navigationItems"
            :key="item"
            :item="item"
          />
          <AppChoiceButton v-if="hasOrg" />
          <ProfileButton />
        </v-row>
      </v-container>
      <MobileNav
        :mobileDialog="displayMobileNav"
        @toggleMobileNav="toggleMobileNav"
        @routeTo="routeTo"
      />
    </v-app-bar>
    <v-footer
      v-if="isMobile"
      id="mobileFooter"
      fixed
      :style="`left: ${footerPadding}px !important`"
    >
      <v-flex
        v-if="isMobile && navigationItems && navigationItems.length > 0"
        class="mx-2 d-flex justify-center"
      >
        <NavigationItems
          v-for="item in navigationItems"
          :key="item"
          :item="item"
        />
      </v-flex>
    </v-footer>
  </div>
</template>

<script>
import Hamburger from "./Hamburger.vue";
import MobileNav from "./MobileNav.vue";
import Cart from "../checkout/Cart.vue";
import ProfileButton from "./ProfileButton.vue";
import AppChoiceButton from "./AppChoiceButton.vue";
import NavigationItems from "./NavigationItems.vue";
export default {
  components: {
    Hamburger,
    MobileNav,
    Cart,
    ProfileButton,
    AppChoiceButton,
    NavigationItems,
  },
  data() {
    return {
      displayMobileNav: false,
      navigationItems: [],
      footerPadding: 0,
    };
  },
  beforeMount() {
    this.navigationItems = this.$route.meta.navigationItems;
  },
  mounted() {
    this.setPadding();
  },
  updated() {
    // Set top padding of dialog to slide under navigation bar
    this.setPadding();
  },
  created() {},
  methods: {
    setPadding() {
      if (!this.footerPadding) {
        let sideMenu = document.getElementById("sideMenu");
        if (sideMenu) {
          this.footerPadding = sideMenu.clientWidth + 10;
        }
      }
    },
    routeTo(route) {
      if (route == "home") {
        this.$router.push({ path: "/" });
      } else if (route.name === "Login") {
        this.$router.push({ path: "login" });
      } else if (route === "profile") {
        this.$router.push({ name: "Profile" });
      } else {
        this.$router.push({ path: route.path });
      }

      this.toggleMobileNav();
    },
    toggleMobileNav() {
      if (this.isMobile) {
        this.displayMobileNav = !this.displayMobileNav;
      }
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    selectedApp() {
      return this.$store.getters.selectedApp;
    },
    userTribe() {
      return this.$store.getters.userTribe;
    },
    chiefedOrgs() {
      return this.$store.getters.chiefedOrgs;
    },
    hasOrg() {
      return this.$store.getters.hasOrg;
    },
  },
  watch: {
    $route(newVal, oldVal) {
      this.navigationItems = newVal.meta.navigationItems;
    },
  },
};
</script>

<style scoped>
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow:
    0px 1px 1px -1px rgb(0 0 0 / 1%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 1px 0px rgb(0 0 0 / 1%);
}
</style>
