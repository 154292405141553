// COMMON
export const COMMON = {
  SET_SNACKBAR_MESSAGE: "SET_SNACKBAR_MESSAGE",
  SET_IS_MOBILE: "SET_IS_MOBILE",
  SET_LOADING: "SET_LOADING",
};

//NAVIGATION EVENTS
export const NAVIGATION_EVENTS = {
  SET_PUBLISH_EVENT_TRIGGER: "SET_PUBLISH_EVENT_TRIGGER",
};

// ROUTING
export const ROUTING = {
  SET_SELECTED_APP: "SET_SELECTED_APP",
  SET_APP_VIEWABILITY: "SET_APP_VIEWABILITY",
};

// vAPI
export const V_API = {
  SET_CHIEFED_ORGS: "SET_CHIEFED_ORGS",
  SET_USER_TRIBE: "SET_USER_TRIBE",
  SET_LOADED_TRIBE: "SET_LOADED_TRIBE",
  SET_RECEIPT_DATA: "SET_RECEIPT_DATA",
  SET_SHOW_LOGIN: "SET_SHOW_LOGIN",
  SET_LOADED_ORG: "SET_LOADED_ORG",
  SET_APP_CONTEXT_ORG: "SET_APP_CONTEXT_ORG",
  SET_ADDRESSES: "SET_ADDRESSES",
  SET_SELECTED_ADDRESS: "SET_SELECTED_ADDRESS",
  SET_USER_LOADING: "SET_USER_LOADING",
};

// Velocity Access Admin
export const ADMIN = {
  SET_VELOCITY_ADMIN: "SET_VELOCITY_ADMIN",
  SET_USER_REPORT_DATA: "SET_USER_REPORT_DATA",
  SET_ORG_REPORT_DATA: "SET_ORG_REPORT_DATA",
};

// EVENTS
export const EVENTS = {
  SET_MARKETPLACE_EVENTS: "SET_MARKETPLACE_EVENTS",
  SET_AVAILABLE_ORG_EVENTS: "SET_AVAILABLE_ORG_EVENTS",
  SET_EVENT: "SET_EVENT",
  SET_EVENT_PRICES: "SET_EVENT_PRICES",
  SET_VENUES: "SET_VENUES",
  SET_EVENTS_WITH_TIMESLOTS: "SET_EVENTS_WITH_TIMESLOTS",
  SET_EVENT_PRODUCTS: "SET_EVENT_PRODUCTS",
};

// PRODUCTS
export const PRODUCTS = {
  SET_NEW_PRODUCT_SCHEMA: "SET_NEW_PRODUCT_SCHEMA",
  SET_NEW_PRODUCT_PRICING_SCHEMA: "SET_NEW_PRODUCT_PRICING_SCHEMA",
  SET_PRODUCT: "SET_PRODUCT",
  SET_STORE_PRODUCTS: "SET_STORE_PRODUCTS",
  SET_USER_PRODUCTS: "SET_USER_PRODUCTS",
  SET_PRODUCT_TAX_CODE_MAPS: "SET_PRODUCT_TAX_CODE_MAPS",
};

// SEATSIO
export const SEATS_IO = {
  SET_CHARTS: "SET_CHARTS",
  SET_CHART: "SET_CHART",
  SET_WORKSPACE_KEYS: "SET_WORKSPACE_KEYS",
};

// PROFILE
export const PROFILE = {
  SET_USER_EVENTS: "SET_USER_EVENTS",
  SET_USER_TICKETS: "SET_USER_TICKETS",
  SET_PAST_ORG_ORDERS: "SET_PAST_ORG_ORDERS",
};

// LOYALTY
export const LOYALTY = {
  SET_PROMO_CODES: "SET_PROMO_CODES",
  SET_PROMO_CODE: "SET_PROMO_CODE",
  SET_PROMO_CODE_SCHEMA: "SET_PROMO_CODE_SCHEMA",
};

// CART
export const CART = {
  SET_USER_CART: "SET_USER_CART",
  SET_CART_PRICES: "SET_CART_PRICES",
  SET_CART_COUNT: "SET_CART_COUNT",
  SET_HOLD_TOKEN: "SET_HOLD_TOKEN",
};

// STORE
export const STORE = {
  SET_BUNDLE: "SET_BUNDLE",
  SET_BUNDLES: "SET_BUNDLES",
  SET_BUNDLE_SCHEMA: "SET_BUNDLE_SCHEMA",
  SET_USER_BUNDLES: "SET_USER_BUNDLES",
  SET_USER_STANDALONE_ASSETS: "SET_USER_STANDALONE_ASSETS",
};

// STRIPE
export const STRIPE = {
  SET_PAYMENT_READER: "SET_PAYMENT_READER",
  SET_PAYMENT_READERS: "SET_PAYMENT_READERS",
  SET_NEW_PAYMENT_READER_SCHEMA: "SET_NEW_PAYMENT_READER_SCHEMA",
  SET_KIOSK_PAYMENT_READER: "SET_KIOSK_PAYMENT_READER",
  SET_PAYMENT_INTENT: "SET_PAYMENT_INTENT",
};
