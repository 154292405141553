/* v8.4.js
 */
// BACKLOG: Get this domain name from Javascript env

var DEBUG = import.meta.env.VITE_DEBUG_LOG;

var vURL = import.meta.env.VITE_VUE_APP_API_PATH;
var vLUurl = vURL + "lu/";

if (DEBUG) {
  console.log(vURL);
  console.log(vLUurl);
}

function vLU() {
  jQuery(document).ready(function () {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function (Code) {
      if (this.readyState == 4 && this.status == 200) {
        var Data = xhttp.responseText;
        var jsonData = JSON.parse(Data);
        if (DEBUG) {
          console.log("LU XHR call successful");
        }
        var El, Head;
        Head = document.getElementsByTagName("head")[0];
        El = document.createElement("script");
        El.type = "text/javascript";
        El.id = "vLUkernel";
        El.appendChild(document.createTextNode(jsonData.kernel));
        Head.appendChild(El);
        if (DEBUG) {
          console.log("Run Visyfy Kernel");
        }
        if (typeof vLoginPrompt === "function") {
          var elements = document.querySelectorAll(".vLoginPrompt");
          elements.forEach(function (element) {
            element.addEventListener("click", function () {
              vLoginPrompt(this);
            });
          });
        }
      } else if (this.status == 0 || this.status == 200) {
        /* Wait */
      } else {
        console.log(
          "ERROR: Live Update pull request failed with response: " +
            JSON.stringify(Code) +
            " Status:" +
            this.status
        );
        alert(
          "We apologize. We can't seem to connect right now. Email us for help:\ntech@velocityaccess.io"
        );
      }
    };
    xhttp.open("GET", vLUurl + "?domain=" + window.location.origin, true);
    console.log(vLUurl + "?domain=" + window.location.origin);
    xhttp.send();
  });
}

function vInit() {
  vLU();
}

window.onload = function () {
  vInit();
};
