<template>
  <div>
    <div class="my-2">
      <span class="spanSmallGrey"> Frequency </span>
      <v-select
        :value="eventFrequency"
        :items="frequencies"
        dense
        hide-details
        outlined
        @change="$emit('update:eventFrequency', $event)"
      ></v-select>
    </div>

    <div class="my-2" v-if="isNotEveryDayOrYearly">
      <div v-if="eventFrequency === 'Custom Dates'">
        <span class="spanSmallGrey"> Selected Dates </span>
        <v-menu
          ref="customDayMenu"
          v-model="customDayMenu"
          :close-on-content-click="false"
          :return-value.sync="customDays"
          min-width="auto"
          offset-y
          small
          transition="scale-transition"
          @click:outside="customDayMenu = false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-combobox
              :value="customDays"
              :multiple="!(eventFrequency === 'Monthly')"
              chips
              dense
              hide-details
              outlined
              readonly
              small-chips
              v-bind="attrs"
              v-on="on"
              @click.native="customDayMenu = true"
              @change="$emit('update:customDays', $event)"
            >
            </v-combobox>
          </template>
          <v-date-picker
            :value="customDays"
            color="velocityBlue"
            hide-details
            multiple
            no-title
            scrollable
            @input="$emit('update:customDays', $event)"
          >
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="customDayMenu = false">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="$refs.customDayMenu.save(customDays)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
      <div v-else>
        <span class="spanSmallGrey"> Days of the Week </span>
        <v-select
          :items="days"
          :multiple="eventFrequency !== 'Every Day'"
          :value="selectedDays"
          dense
          hide-details
          outlined
          @change="$emit('update:selectedDays', $event)"
        ></v-select>
      </div>

      <div v-if="eventFrequency === 'Monthly'" class="my-2">
        <span class="spanSmallGrey"> Weeks of the Month </span>
        <v-select
          :multiple="chosenWeekOfMonth !== 'Every Day'"
          :value="chosenWeekOfMonth"
          :items="weekItems"
          dense
          hide-details
          outlined
          @change="$emit('update:chosenWeekOfMonth', $event)"
        ></v-select>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Frequency-selector",
  props: {
    chosenWeekOfMonth: {
      type: [Array, String],
      default: function () {
        return Array.isArray(this.selectedDays) ? [] : "";
      },
    },
    customDays: {
      type: Array,
      default: () => [],
    },
    eventFrequency: {
      type: String,
      default: () => "",
    },
    selectedDays: {
      type: [Array, String],
      default: function () {
        return Array.isArray(this.selectedDays) ? [] : "";
      },
    },
  },
  data() {
    return {
      customDayMenu: false,
      days: ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"],
      frequencies: [
        // "Every Day",
        // "Weekly",
        // "Monthly",
        // "Annually",
        "Custom Dates",
      ],
    };
  },
  computed: {
    isNotEveryDayOrYearly() {
      return (
        this.eventFrequency !== "Every Day" &&
        this.eventFrequency !== "Annually"
      );
    },
    weekItems() {
      return [
        { text: "First Week", value: 1 },
        { text: "Second Week", value: 2 },
        { text: "Third Week", value: 3 },
        { text: "Fourth Week", value: 4 },
        { text: "Last Week", value: 5 },
      ];
    },
  },
};
</script>
