import * as types from "../mutation-types";

export default {
  state: {
    workspaceKeys: {},
    charts: [],
  },
  getters: {
    workspaceKeys: (state) => state.workspaceKeys,
    charts: (state) => state.charts,
  },
  mutations: {
    [types.SEATS_IO.SET_WORKSPACE_KEYS](state, orgObject) {
      this._vm.$set(
        state.workspaceKeys,
        orgObject.orgID,
        orgObject.workspaceKey
      );
      state.workspaceKeys;
    },
    [types.SEATS_IO.SET_CHARTS](state, charts) {
      state.charts = charts;
    },
  },
  actions: {
    releaseSeatsObjects(ctx, releaseObject) {
      vDo("integration/seats/release_objects", releaseObject, (response) => {
        console.log("released");
      });
    },
    fetchOrgWorkspacekey(ctx, orgID) {
      if (!ctx.state.workspaceKeys[orgID]) {
        vDo(
          "integration/seats/fetch_workspace_from_org",
          { org_id: orgID },
          (response) => {
            if (response["x"] == 200) {
              let orgObject = { orgID: orgID, workspaceKey: response.data };
              ctx.commit(types.SEATS_IO.SET_WORKSPACE_KEYS, orgObject);
            }
          }
        );
      }
    },
    loadSeatCharts(ctx, queryObject) {
      if (queryObject.org_id) {
        vDo("integration/seats/charts", queryObject, (response) => {
          ctx.commit(types.SEATS_IO.SET_CHARTS, response.data.items);
        });
      }
    },
  },
};
