
<div>
  <v-flex d-flex flex-column class="pa-6">
    <div>
      <label class="text-body-2" for=""
        >Full name (First and Last name)</label
      >
      <v-text-field
        id="name"
        class="mt-2"
        placeholder="Full Name"
        solo
        dense
        v-model="name"
      ></v-text-field>
    </div>
    <div>
      <label class="text-body-2">Phone Number</label>
      <v-text-field
        id="phonenumber"
        class="mt-2"
        placeholder="Phone Number"
        solo
        dense
        v-model="phone"
      ></v-text-field>
    </div>
    <span> Address </span>
    <PlacesAutocomplete
      :place="place"
      :label="'Street address'"
      @updatePlace="updatePlace"
    />
    <div>
      <label class="text-body-2">City</label>
      <v-text-field
        :loading="loading"
        id="city"
        class="mt-2"
        placeholder="City"
        solo
        dense
        v-model="city"
      ></v-text-field>
    </div>
    <div class="d-flex justify-space-between">
      <div>
        <label class="text-body-2"> State </label>
        <v-autocomplete
          :loading="loading"
          hide-details
          single-line
          autocomplete="null"
          outlined
          :items="states"
          v-model="state"
          placeholder="Utah"
          dense
          hide-no-data
          item-text="label"
          return-object
          color="velocityBlue"
        >
        </v-autocomplete>
      </div>
      <div class="ml-2">
        <label class="text-body-2">ZIP Code</label>
        <v-text-field
          :loading="loading"
          id="postalCode"
          solo
          dense
          v-model="postalCode"
        ></v-text-field>
      </div>
    </div>
    <v-checkbox
      v-if="!forceDefault"
      class="mt-6"
      dense
      v-model="defaultAddr"
      label="Make this my default address"
    ></v-checkbox>
  </v-flex>
  <v-footer>
    <v-btn class="velocityBlue white--text" block @click="saveAddress">
      Save Address
    </v-btn>
  </v-footer>
</div>
