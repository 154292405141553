import * as types from "../mutation-types";
export default {
  state: {
    events: [],
    eventsWithTimeslots: [],
    event: {},
    marketplaceResults: [],
    userEvents: [],
    userTickets: [],
    networkLoading: false,
    venues: [],
    pastOrgOrders: [],
    eventProducts: [],
  },
  getters: {
    events: (state) => state.events,
    eventsWithTimeslots: (state) => state.eventsWithTimeslots,
    event: (state) => state.event,
    marketplaceResults: (state) => state.marketplaceResults,
    userEvents: (state) => state.userEvents,
    userTickets: (state) => state.userTickets,
    networkLoading: (state) => state.networkLoading,
    venues: (state) => state.venues,
    pastOrgOrders: (state) => state.pastOrgOrders,
    eventProducts: (state) => state.eventProducts,
  },
  mutations: {
    [types.EVENTS.SET_AVAILABLE_ORG_EVENTS](state, events) {
      state.events = events;
    },
    [types.EVENTS.SET_EVENT](state, event) {
      state.event = event;
    },
    [types.EVENTS.SET_MARKETPLACE_EVENTS](state, events) {
      state.marketplaceResults = events;
    },
    [types.PROFILE.SET_USER_EVENTS](state, userEvents) {
      state.userEvents = userEvents;
    },
    [types.PROFILE.SET_USER_TICKETS](state, userTickets) {
      state.userTickets = userTickets;
    },

    [types.EVENTS.SET_VENUES](state, venues) {
      state.venues = venues;
    },
    [types.PROFILE.SET_PAST_ORG_ORDERS](state, pastOrgOrders) {
      state.pastOrgOrders = pastOrgOrders;
    },
    [types.EVENTS.SET_EVENTS_WITH_TIMESLOTS](state, eventsWithTimeslots) {
      state.eventsWithTimeslots = eventsWithTimeslots;
    },
    [types.EVENTS.SET_EVENT_PRODUCTS](state, eventProducts) {
      for (var i = 0; i < eventProducts.length; i++) {
        let active = eventProducts[i].active;
        if (active === "t") {
          eventProducts[i].active = true;
        } else if (active === "f") {
          eventProducts[i].active = false;
        }
        let accessible = eventProducts[i].accessible;
        if (accessible === "t") {
          eventProducts[i].accessible = true;
        } else if (accessible === "f") {
          eventProducts[i].accessible = false;
        }
        let tax_exempt = eventProducts[i].tax_exempt;
        if (tax_exempt === "t") {
          eventProducts[i].tax_exempt = true;
        } else if (tax_exempt === "f") {
          eventProducts[i].tax_exempt = false;
        }
        let is_addon = eventProducts[i].is_addon;
        if (tax_exempt === "t") {
          eventProducts[i].is_addon = true;
        } else if (is_addon === "f") {
          eventProducts[i].is_addon = false;
        }
      }
      state.eventProducts = eventProducts;
    },
  },
  actions: {
    saveEvent(ctx, eventObj) {
      ctx.dispatch("setLoading", true);
      vDo("event/create_event", eventObj, (response) => {
        ctx.dispatch("setLoading", false);
        if (response.x == 200) {
          eventObj.onSuccessCallback(response.data.id);
        } else {
          if (eventObj.event.id) {
            eventObj.onSuccessCallback(eventObj.event.id);
          } else {
            eventObj.onSuccessCallback(false);
          }

          let error =
            "Something went wrong, please refresh and try again. If this continues to happen, please contact support at tech@velocityaccess.io";
          if (response.data) {
            if (response.data == "WARNING: No products provided") {
              error = "Event will stay as draft until Ticket Types are created";
            } else {
              error = response.data;
            }
          }
          ctx.dispatch("setSnackBarMessage", {
            message: error,
            type: "FAILURE",
          });
        }
        ctx.dispatch("loadOrgEvents");
      });
    },
    clearSelectedEvent(ctx) {
      ctx.commit(types.EVENTS.SET_EVENT, {});
    },
    loadOrgEvents(ctx, filterObj) {
      if (ctx.getters.userTribe.id) {
        let filters = filterObj ? filterObj : {};
        filters["org_id"] = ctx.getters.appContextOrg.id
          ? ctx.getters.appContextOrg.id
          : ctx.getters.userTribe.meta["app_context_org"];

        vDo("event/fetch_events", filters, (response) => {
          ctx.commit(types.EVENTS.SET_AVAILABLE_ORG_EVENTS, response.data);
        });
      }
    },
    loadEventProducts(ctx, eventID) {
      ctx.commit(types.EVENTS.SET_EVENT_PRODUCTS, []);
      vDo(
        "event/fetch_products_for_event",
        { event_id: eventID },
        (response) => {
          if (response.x == 200) {
            ctx.commit(types.EVENTS.SET_EVENT_PRODUCTS, response.data);
          } else {
            ctx.commit(types.EVENTS.SET_EVENT_PRODUCTS, []);
          }
        }
      );
    },
    clearEventProducts(ctx) {
      ctx.commit(types.EVENTS.SET_EVENT_PRODUCTS, []);
    },
    setModifiedEventProducts(ctx, products) {
      ctx.commit(types.EVENTS.SET_EVENT_PRODUCTS, products);
    },
    loadOrgEventsRevenue(ctx, filterObj) {
      ctx.dispatch("setLoading", true);
      if (ctx.getters.userTribe.id) {
        let filters = filterObj ? filterObj : {};
        filters["org_id"] = ctx.getters.appContextOrg.id
          ? ctx.getters.appContextOrg.id
          : ctx.getters.userTribe.meta["app_context_org"];

        vDo("analytics/organization/all_event_revenue", filters, (response) => {
          ctx.dispatch("setLoading", false);
          ctx.commit(types.EVENTS.SET_AVAILABLE_ORG_EVENTS, response.data);
        });
      }
    },
    clearOrgEvents(ctx) {
      if (
        ctx.state.events &&
        ctx.state.events.length > 0 &&
        ctx.getters.appContextOrg &&
        ctx.state.events[0].org != ctx.getters.appContextOrg.id
      ) {
        ctx.commit(types.EVENTS.SET_AVAILABLE_ORG_EVENTS, []);
      }
    },
    loadEvent(ctx, eventObj) {
      let eventID = eventObj?.event ? eventObj["event"] : eventObj;
      if (
        ctx.state.event.id != eventID ||
        (eventObj?.forceRefresh && eventID)
      ) {
        vDo("event/fetch_event", { event_id: eventID }, (response) => {
          ctx.commit(types.EVENTS.SET_EVENT, response.data);
          if (eventObj?.onSuccessCallback) {
            eventObj.onSuccessCallback();
          }
        });
      }
    },
    searchMarketplace(ctx, filters) {
      ctx.state.networkLoading = true;
      vDo("marketplace/search", filters, (response) => {
        ctx.commit(types.EVENTS.SET_MARKETPLACE_EVENTS, response.data);
        ctx.state.networkLoading = false;
      });
    },
    loadUserEvents(ctx) {
      vDo("userprofile/events", {}, (response) => {
        ctx.commit(types.PROFILE.SET_USER_EVENTS, response.data);
      });
    },
    loadUserTickets(ctx, eventID) {
      vDo(
        "userprofile/tickets_per_event",
        { event_id: eventID },
        (response) => {
          ctx.commit(types.PROFILE.SET_USER_TICKETS, response.data);
        }
      );
    },
    loadVenues(ctx) {
      if (ctx.getters.appContextOrg.id) {
        vDo(
          "analytics/event/venues",
          { org_id: ctx.getters.appContextOrg.id },
          (response) => {
            ctx.commit(types.EVENTS.SET_VENUES, response.data);
          }
        );
      }
    },
    loadPastOrgOrders(ctx, searchTerm) {
      if (ctx.getters.appContextOrg.id) {
        ctx.dispatch("setLoading", true);
        vDo(
          "analytics/transactions/past_org_orders",
          { org_id: ctx.getters.appContextOrg.id, search_term: searchTerm },
          (response) => {
            ctx.commit(types.PROFILE.SET_PAST_ORG_ORDERS, response.data);
            ctx.dispatch("setLoading", false);
          }
        );
      }
    },
    loadEventsWithTimeslots(ctx, currentOnly) {
      //Pass true to only return events that are "current" (end time < now())
      vDo(
        "event/fetch_events_with_timeslots",
        { org_id: ctx.getters.appContextOrg.id, current_only: currentOnly },
        (response) => {
          ctx.commit(types.EVENTS.SET_EVENTS_WITH_TIMESLOTS, response.data);
        }
      );
    },
  },
};
