<template>
  <TicketType
    v-if="productToEdit.id"
    :product="productToEdit"
    :products="products"
    :forceOpenEdit="true"
  />
</template>

<script>
import TicketType from "./TicketType.vue";
export default {
  components: {
    TicketType,
  },
  props: { selectedCategory: Object },
  data: () => ({
    products: [],
    productToEdit: {},
    randomTicketTypes: [
      { name: "General Admission", quantity: "100", price: "10.25" },
      { name: "Box Seating", quantity: "55", price: "15.65" },
      { name: "VIP Admission", quantity: "25", price: "100.05" },
      { name: "Backstage Access", quantity: "10", price: "60.00" },
      { name: "Accessible Seating", quantity: "25", price: "21.55" },
    ],
  }),
  created() {
    this.products = this.eventProducts;
  },
  mounted() {},
  methods: {
    initializeData() {},
    loadSeatCharts() {},
    addNewProduct() {
      const newProduct = { ...this.newProductSchema };
      newProduct["id"] = vGUID();
      newProduct["placeholder"] = newProduct["id"];
      newProduct["integrations"] = { sio: { category: this.selectedCategory } };
      newProduct["name"] = this.selectedCategory.label;
      newProduct["accessible"] = this.selectedCategory.accessible;
      // newProduct["sio_quantity"] = this.selectedCategory.quantity;
      // set quantity to -1 for SIO and let SIO handle quantity in embed
      newProduct["quantity"] = -1;
      this.products.push(newProduct);
      this.productToEdit = newProduct;
    },
    getRandomTicketType() {
      const randomIndex = Math.floor(
        Math.random() * this.randomTicketTypes.length
      );

      return this.randomTicketTypes[randomIndex];
    },
    findProductWithCategory() {
      if (this.selectedCategory.key && this.products.length > 0) {
        for (var i = 0; i < this.products.length; i++) {
          let product = this.products[i];
          if (
            product.integrations &&
            product.integrations.sio &&
            product.integrations.sio.category
          ) {
            if (
              product.integrations.sio.category.key == this.selectedCategory.key
            ) {
              // product.quantity = this.selectedCategory.quantity;
              product.quantity = -1;
              return product;
            }
          }
        }
      }
      return {};
    },
  },
  computed: {
    eventProducts() {
      return this.$store.getters.eventProducts;
    },
    newProductSchema() {
      return this.$store.getters.newProductSchema;
    },
  },
  watch: {
    selectedCategory(newVal) {
      this.productToEdit = this.findProductWithCategory();
      if (!this.productToEdit.id) {
        this.addNewProduct();
      }
    },
    eventProducts(newVal) {
      if (newVal) {
        this.products = newVal;
      }
    },
  },
};
</script>

<style></style>
