import * as types from "../mutation-types";

export default {
  state: {
    vAdmin: null,
    userReportData: null,
    orgReportData: null,
  },
  getters: {
    vAdmin: (state) => state.vAdmin,
    userReportData: (state) => state.userReportData,
    orgReportData: (state) => state.orgReportData,
  },
  mutations: {
    [types.ADMIN.SET_VELOCITY_ADMIN](state, vAdmin) {
      state.vAdmin = vAdmin;
    },
    [types.ADMIN.SET_USER_REPORT_DATA](state, userReportData) {
      state.userReportData = userReportData;
    },
    [types.ADMIN.SET_ORG_REPORT_DATA](state, orgReportData) {
      state.orgReportData = orgReportData;
      console.log(state.orgReportData);
    },
  },
  actions: {
    checkAdminAccess(ctx) {
      vDo("vac/v_a", {}, (response) => {
        //vac == admin access
        let isAdmin = response["data"];

        ctx.commit(types.ADMIN.SET_VELOCITY_ADMIN, isAdmin);
      });
    },
    userReportGenerator(ctx, reportObject) {
      vDo(
        `vac/user/${reportObject.report}`, //vac == admin access
        { query: reportObject.query },
        (response) => {
          if (reportObject.callBack) {
            reportObject.callBack();
          }
          if (response["x"] != 200) {
            ctx.dispatch("setSnackBarMessage", {
              message: response["data"],
              type: "FAILURE",
            });
          } else {
            ctx.commit(types.ADMIN.SET_USER_REPORT_DATA, response["data"]);
          }
        }
      );
    },
    userDataReport(ctx, userId) {},
    orgReportGenerator(ctx, reportObject) {
      vDo(
        `vac/org/${reportObject.report}`, //vac == admin access
        { query: reportObject.query },
        (response) => {
          if (reportObject.callBack) {
            reportObject.callBack();
          }
          if (response["x"] != 200) {
            ctx.dispatch("setSnackBarMessage", {
              message: response["data"],
              type: "FAILURE",
            });
          } else {
            ctx.commit(types.ADMIN.SET_ORG_REPORT_DATA, response["data"]);
          }
        }
      );
    },
  },
};
