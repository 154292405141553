<template>
  <div>
    <v-flex d-flex flex-column class="pa-6">
      <div>
        <label class="text-body-2" for=""
          >Full name (First and Last name)</label
        >
        <v-text-field
          id="name"
          class="mt-2"
          placeholder="Full Name"
          solo
          dense
          v-model="name"
        ></v-text-field>
      </div>
      <div>
        <label class="text-body-2">Phone Number</label>
        <v-text-field
          id="phonenumber"
          class="mt-2"
          placeholder="Phone Number"
          solo
          dense
          v-model="phone"
        ></v-text-field>
      </div>
      <span> Address </span>
      <PlacesAutocomplete
        :place="place"
        :label="'Street address'"
        @updatePlace="updatePlace"
      />
      <div>
        <label class="text-body-2">City</label>
        <v-text-field
          :loading="loading"
          id="city"
          class="mt-2"
          placeholder="City"
          solo
          dense
          v-model="city"
        ></v-text-field>
      </div>
      <div class="d-flex justify-space-between">
        <div>
          <label class="text-body-2"> State </label>
          <v-autocomplete
            :loading="loading"
            hide-details
            single-line
            autocomplete="null"
            outlined
            :items="states"
            v-model="state"
            placeholder="Utah"
            dense
            hide-no-data
            item-text="label"
            return-object
            color="velocityBlue"
          >
          </v-autocomplete>
        </div>
        <div class="ml-2">
          <label class="text-body-2">ZIP Code</label>
          <v-text-field
            :loading="loading"
            id="postalCode"
            solo
            dense
            v-model="postalCode"
          ></v-text-field>
        </div>
      </div>
      <v-checkbox
        v-if="!forceDefault"
        class="mt-6"
        dense
        v-model="defaultAddr"
        label="Make this my default address"
      ></v-checkbox>
    </v-flex>
    <v-footer>
      <v-btn class="velocityBlue white--text" block @click="saveAddress">
        Save Address
      </v-btn>
    </v-footer>
  </div>
</template>

<script>
import { getStatesArray } from "@/utility/states";
import PlacesAutocomplete from "@/components/app/PlacesAutocomplete.vue";
export default {
  components: { PlacesAutocomplete },
  props: {
    tribe: String,
  },
  name: "EditAddress",
  data: () => ({
    name: "",
    phone: "",
    city: "",
    place: {},
    postalCode: "",
    postalCodeSuffix: "",
    defaultAddr: false,
    forceDefault: false,
    state: {},
    country: "United States",
    countryCode: "US",
    county: "",
    streetNumber: "",
    route: "",
    loading: false,
  }),
  created() {
    this.initializeData();
    this.setDefault();
  },
  destroyed() {},
  mounted() {},
  methods: {
    initializeData() {
      if (this.userTribe.id) {
        this.name =
          this.userTribe["meta"]["first_name"] +
          " " +
          this.userTribe["meta"]["last_name"];
        this.phone = this.userTribe["phone"];
      }
    },
    updatePlace(data) {
      this.place = data;
      this.loading = true;
      vDo(
        "integration/google/place_details",
        { place_id: this.place.place_id },
        (response) => {
          if (response["x"] == 200 && response["data"]["status"] == "OK")
            this.autoFillAddress(response.data.result["address_components"]);
        }
      );
    },
    autoFillAddress(addressComponents) {
      for (var i = 0; i < addressComponents.length; i++) {
        let component = addressComponents[i];
        let type = component["types"][0];
        if (type == "locality") {
          this.city = component.short_name;
        } else if (type == "administrative_area_level_1") {
          this.state = component.short_name;
        } else if (type == "administrative_area_level_2") {
          this.county = component.short_name;
        } else if (type == "country") {
          this.country = component.long_name;
          this.countryCode = component.short_name;
        } else if (type == "postal_code") {
          this.postalCode = component.short_name;
        } else if (type == "postal_code_suffix") {
          this.postalCodeSuffix = component.short_name;
        } else if (type == "street_number") {
          this.streetNumber = component.long_name;
        } else if (type == "route") {
          this.route = component.long_name;
        }
      }
      this.loading = false;
    },
    validateAddress() {
      return true;
    },
    saveAddress() {
      if (this.validateAddress) {
        let address = {
          full_name: this.name,
          phone: this.phone,
          city: this.city,
          state: this.state,
          country_code: this.countryCode,
          country: this.country,
          postal_code: this.postalCode,
          postal_code_suffix: this.postalCodeSuffix,
          place_id: this.place.place_id,
          default: this.forceDefault ? this.forceDefault : this.defaultAddr,
          complete_address: this.place.description,
          county: this.county,
          street_number: this.streetNumber,
          route: this.route,
        };

        vDo(
          "addresses/create_address",
          { address: address, tribe: this.tribe },
          (response) => {
            if (response["x"] == 200) {
              this.$store.dispatch("loadAddresses", this.tribe);
              this.$emit("editingAddress", false);
            } else {
              this.$store.dispatch("setSnackBarMessage", {
                message: response["data"],
                type: "FAILURE",
              });
            }
          }
        );
      } else {
        // TODO: Implement error for invalid address
      }
    },
    setDefault() {
      if (this.addresses.length == 0) {
        this.forceDefault = true;
      }
    },
  },
  computed: {
    states() {
      return getStatesArray();
    },
    userTribe() {
      return this.$store.getters.userTribe;
    },
    addresses() {
      return this.$store.getters.addresses;
    },
  },
  watch: {
    userTribe(newVal) {
      this.initializeData();
    },
    addresses(newVal) {
      this.setDefault();
    },
  },
};
</script>

<style scoped></style>
