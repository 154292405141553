
<v-navigation-drawer id="sideMenu" permanent app :mini-variant="miniDrawer">
  <div class="justify-center mb-8 d-flex">
    <OrganizationPickerBtn :miniVariant="miniDrawer" />
  </div>
  <v-flex v-if="!isMobile" class="d-flex justify-right">
    <v-spacer />
    <v-btn @click="miniDrawer = !miniDrawer" outlined icon>
      <v-icon height="40px" width="40px">
        {{ miniDrawer ? "mdi-chevron-right" : "mdi-chevron-left" }}
      </v-icon>
    </v-btn>
    <v-spacer />
  </v-flex>
  <v-divider v-if="!isMobile && overviewApp['name']" class="my-4" />
  <v-list v-if="hasOrg" class="mb-4" nav dense>
    <v-list-item
      @click="routeTo(item)"
      v-for="item in overviewApp.routes"
      :key="item.name"
      link
    >
      <v-tooltip :disabled="!miniDrawer" right>
        <template v-slot:activator="{ on, attrs }">
          <v-flex v-bind="attrs" v-on="on" class="d-flex">
            <v-list-item-icon>
              <v-icon
                class="pr-2"
                :style="`${
                  $route.path == item.path
                    ? `color: ${theme.velocityBlue}`
                    : ''
                }`"
                >{{ item.icon }}</v-icon
              >
            </v-list-item-icon>
            <v-list-item-title
              class="py-1"
              :style="`${
                $route.path == item.path
                  ? `color: ${theme.velocityBlue}; font-size: 1em;`
                  : 'font-size: 1em;'
              }`"
              >{{ item.name }}
            </v-list-item-title>
          </v-flex>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </v-list-item>
  </v-list>
  <span
    v-if="selectedApp.name != 'Overview' && !miniDrawer"
    class="spanSmallGrey ml-4"
    style="font-weight: bold"
  >
    {{ selectedApp["name"] }}</span
  >
  <v-list
    v-if="selectedApp.routes.length > 0 && selectedApp.name != 'Overview'"
    nav
    dense
  >
    <v-list-item
      @click="routeTo(item)"
      v-for="item in selectedApp.routes"
      :key="item.name"
      link
    >
      <v-tooltip :disabled="!miniDrawer" right>
        <template v-slot:activator="{ on, attrs }">
          <v-flex v-bind="attrs" v-on="on" class="d-flex">
            <v-list-item-icon>
              <v-icon
                class="pr-2"
                :style="`${
                  $route.path == item.path
                    ? `color: ${theme.velocityBlue}`
                    : ''
                }`"
                >{{ item.icon }}</v-icon
              >
            </v-list-item-icon>
            <v-list-item-title
              class="py-1"
              :style="`${
                $route.path == item.path
                  ? `color: ${theme.velocityBlue}; font-size: 1em;`
                  : 'font-size: 1em;'
              }`"
              >{{ item.name }}
            </v-list-item-title>
          </v-flex>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </v-list-item>
  </v-list>
</v-navigation-drawer>
