import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

// https://brand.byu.edu/colors
const theme = {
  primary: "#003DA5",
  secondary: "#FFFFFF",
  accent: "#002E5D",
  error: "#E11900",
  velocityBlue: "#276EF1",
  lightBlue: "#5B91F5",
  appBackground: "#f9f9f9",
};

export default new Vuetify({
  theme: {
    themes: {
      light: theme,
      dark: theme,
    },
  },
});
