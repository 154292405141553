<template>
  <v-btn class="velocityBlue white--text" @click="setEventTrigger">
    Publish Event
  </v-btn>
</template>

<script>
export default {
  components: {},
  name: "PublishEventBtn",
  data: () => ({}),
  methods: {
    setEventTrigger() {
      this.$store.dispatch("setPublishEventTrigger", true);
    },
  },
  watch: {},
};
</script>

<style scoped></style>
