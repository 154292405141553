<template>
  <v-dialog
    fullscreen
    transition="dialog-bottom-transition"
    v-model="creatingEvent"
    persistent
  >
    <v-card class="pb-12">
      <v-container :class="isMobile ? 'ma-0 pa-0' : ''">
        <v-flex class="d-flex justify-right">
          <v-spacer />
          <v-btn
            :class="isMobile ? 'mr-5 mt-2' : ''"
            color="black"
            icon
            @click="closeDialog()"
          >
            <v-icon large color="black" style="font-size: 24px"
              >mdi-close</v-icon
            >
          </v-btn>
        </v-flex>
        <v-row no-gutters class="pb-15 mb-15 d-flex justify-center">
          <v-col cols="12" lg="7" md="10" sm="12">
            <div class="mx-3">
              <div class="py-2 mb-0 pb-0">
                <span class="spanBoldMedium">Create an Event</span>
              </div>
              <div class="mb-5">
                <span class="spanSmallGrey">
                  Events start as a draft. You can edit this information later
                  on.
                </span>
              </div>
              <span class="spanSmallGrey mt-8"> Event Name </span>
              <v-text-field
                dense
                class="pt-0 mt-1"
                v-model="meta.name"
                clear-icon="mdi-close-circle"
                clearable
                outlined
                single-line
                placeholder="Improv Comedy Night"
                type="text"
                @click:clear="meta.name = ''"
                color="velocityBlue"
              >
              </v-text-field>
              <EventDatePicker
                ref="datePicker"
                :show-recurring-events-option="false"
                :meta="meta"
              />
              <EventSeatingTypeSelector :meta="meta" @setMeta="setMeta" />
              <!-- Places Search -->
              <Addresses
                :address="meta.address"
                :label="'event'"
                :tribe="appContextOrg.id"
                class="my-6"
              />
            </div>
            <div class="pb-6">
              <span class="spanBoldSmall"> {{ `Event Settings` }} </span>
              <v-divider class="mt-5 mb-4" />
              <span class="spanSmallGrey">
                {{ `Who can access this event` }}
              </span>
              <v-radio-group v-model="eventVisibility" row>
                <v-radio
                  color="velocityBlue"
                  label="Public"
                  value="public"
                ></v-radio>
                <v-radio
                  color="velocityBlue"
                  label="Private"
                  value="private"
                ></v-radio>
              </v-radio-group>
            </div>
            <div>
              <v-btn class="velocityBlue white--text" block @click="saveEvent">
                Create event as draft
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AlertComponent from "@/components/app/AlertComponent.vue";
import LoadingSpinner from "../../components/app/LoadingSpinner.vue";
import PlacesAutocomplete from "@/components/app/PlacesAutocomplete.vue";
import EventDatePicker from "@/components/events/Event-picker/EventDatePicker.vue";
import Addresses from "@/components/addresses/Addresses.vue";
import EventSeatingTypeSelector from "../../components/events/EventSeatingTypeSelector.vue";

export default {
  props: {
    creatingEvent: Boolean,
  },
  components: {
    LoadingSpinner,
    AlertComponent,
    PlacesAutocomplete,
    EventDatePicker,
    Addresses,
    EventSeatingTypeSelector,
  },
  name: "EventBuilder",
  data: () => ({
    eventVisibility: "public",
    loading: false,
    meta: {},
    event: {},
    setTopPadding: 0,
  }),
  created() {
    this.$store.dispatch("clearSelectedEvent");
    this.$store.dispatch("clearEventProducts");
    this.loadNewEvent();
  },
  mounted() {
    this.setPadding();
  },
  updated() {
    this.setPadding();
  },
  beforeCreate() {
    document.getElementsByTagName("body")[0].className = "noscroll";
  },
  beforeDestroy() {
    document.body.removeAttribute("class", "noscroll");
  },
  methods: {
    setPadding() {
      if (!this.setTopPadding) {
        let dialog = document.getElementsByClassName("v-dialog");
        for (var i = 0; i < dialog.length; i++) {
          this.setTopPadding = document.getElementById("appbar").clientHeight;
          dialog[i].style["top"] = `${this.setTopPadding}px`;
        }
      }
    },
    loadNewEvent() {
      vDo("event/fetch_event", { event_id: "new" }, (response) => {
        this.event = response.data;
      });
    },
    closeDialog() {
      this.$emit("closeEvent");
    },
    setMeta(meta) {
      this.meta = meta;
    },
    saveEvent() {
      this.loading = true;
      let datePicker = this.$refs["datePicker"];
      if (datePicker) {
        let completedDates = datePicker.completedDates;
        this.meta.startTime = completedDates["startDateUTC"];
        this.meta.endTime = completedDates["endDateUTC"];
        this.meta.timezone = completedDates["timezone"];
      }

      this.meta.visibility = this.eventVisibility;

      if (this.selectedAddress.id) {
        this.meta.address = this.selectedAddress;
      } else {
        delete this.meta.address;
      }
      this.meta.status = "draft";
      if (!this.meta.seating_type) {
        this.meta.seating_type = "general_admission";
      }

      this.event.meta = this.meta;
      let saveObj = {
        event: this.event,
        products: this.eventProducts,
        org_id: this.appContextOrg.id,
        onSuccessCallback: this.routeToEvent,
      };

      this.$store.dispatch("saveEvent", saveObj);
    },
    routeToEvent(id) {
      if (id == false) {
        this.loading = false;
      } else {
        this.$router.push({
          name: "EventBuilderExpanded",
          params: {
            eventID: id,
            tab: "eventdetails",
          },
        });
        this.loading = false;
        this.closeDialog();
      }
    },
    initializeData() {
      this.meta = this.event.meta || {};
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    appContextOrg() {
      return this.$store.getters.appContextOrg;
    },
    selectedAddress() {
      return this.$store.getters.selectedAddress;
    },
    eventProducts() {
      return this.$store.getters.eventProducts;
    },
  },
  watch: {
    event: {
      handler(newVal, oldVal) {
        this.initializeData();
      },
      deep: true,
    },
    placesSearch(newVal) {
      vDo("marketplace/places_autocomplete", { input: newVal }, (response) => {
        this.placesResults = response.data.predictions;
      });
    },
  },
};
</script>

<style scoped>
.noscroll {
  position: fixed;
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>
