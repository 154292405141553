
<div>
  <v-flex
    v-for="(product, index) in products"
    :key="index + product.id ? product.id : '-id'"
    :class="`d-flex flex-column pa-2 px-4 mb-2 fullBorder`"
    style="cursor: pointer"
  >
    <TicketType :product="product" :products="products" />
  </v-flex>
  <a class="spanBoldSmall" style="cursor: pointer" @click="addNewProduct">
    {{ `+ Add Ticket Type` }}
  </a>
</div>
