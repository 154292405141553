<template>
  <div class="mx-2">
    <CreateEventBtn v-if="item == 'createEvent'" />
    <PublishEventBtn v-else-if="item == 'publishEvent'" />
  </div>
</template>

<script>
import CreateEventBtn from "@/components/dashboard/CreateEventBtn.vue";
import PublishEventBtn from "../dashboard/PublishEventBtn.vue";

export default {
  props: {
    item: String,
  },
  components: {
    CreateEventBtn,
    PublishEventBtn,
  },
};
</script>

<style></style>
