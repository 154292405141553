<template>
  <div>
    <v-autocomplete
      v-model="selectedPlace"
      :items="placesResults"
      :search-input.sync="placesSearch"
      clearable
      dense
      solo
      item-text="description"
      item-value="place_id"
      return-object
      :label="label ? label : 'Address'"
      prepend-inner-icon="mdi-map-marker-outline"
      no-filter
      hide-no-data
      @change="updatePlace()"
      color="velocityBlue"
    ></v-autocomplete>
  </div>
</template>
<script>
import { debounce } from "@/utility/helperFunctions";
export default {
  name: "PlacesAutocomplete",
  props: {
    place: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    debouncedSearch: "",
    placesSearch: "",
    placesResults: [],
    selectedPlace: {},
  }),
  mounted() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      if (Object.keys(this.place).length != 0) {
        this.selectedPlace = this.place;
        this.placesSearch = this.place.description;
        this.loadPlace();
      }
    },
    updatePlace() {
      if (!this.selectedPlace) {
        this.placesSearch = "";
        this.$emit("updatePlace", {});
      } else {
        this.selectedPlace = {
          place_id: this.selectedPlace.place_id,
          description: this.selectedPlace.description,
          title: this.selectedPlace.structured_formatting.main_text,
          subtitle: this.selectedPlace.structured_formatting.secondary_text,
        };
        this.placesSearch = this.selectedPlace.description;
        if (this.place.place_id != this.selectedPlace.place_id) {
          this.$emit("updatePlace", this.selectedPlace);
        }
      }
    },
    loadPlace() {
      if (
        this.placesSearch ||
        !this.place ||
        this.place.place_id != this.selectedPlace.place_id ||
        this.placesResults.length == 0
      ) {
        vDo(
          "integration/google/places",
          { input: this.placesSearch },
          (response) => {
            this.placesResults = response.data.predictions;
          }
        );
      }
    },
  },
  watch: {
    place(newVal) {
      this.initializeData();
    },
    debouncedSearch(newVal) {
      if (newVal) {
        this.loadPlace();
      }
    },
    placesSearch: debounce(function (newVal) {
      if (newVal && newVal.length > 3) {
        this.debouncedSearch = newVal;
      }
    }, 700),
  },
};
</script>
