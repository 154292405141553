
<v-snackbar
  @click.native="closeSnackbar"
  class="mt-15"
  v-model="show"
  :timeout="snackbarMessage.time ? snackbarMessage.time : this.time"
  :color="'velocityBlue'"
  elevation="6"
  text
  top
  multi-line
>
  <!-- :color="snackbarMessage.type ? snackbarMessage.type : 'velocityBlue'" -->
  <span class="black--text">
    {{ snackbarMessage.message }}
  </span>
  <template v-slot:action="{ attrs }">
    <v-btn text icon v-bind="attrs" @click.stop="closeSnackbar">
      <v-icon> mdi-close </v-icon>
    </v-btn>
  </template>
</v-snackbar>
