import * as types from "../mutation-types";

// HELPER STORE TO TRIGGER EVENTS FROM NAVIGATION BAR.
// ANY NEW TRIGGER SHOULD SET SELF TO FALSE AFTER REQUESTED ACTION
export default {
  state: {
    publishEventTrigger: false,
  },
  getters: {
    publishEventTrigger: (state) => state.publishEventTrigger,
  },
  mutations: {
    [types.NAVIGATION_EVENTS.SET_PUBLISH_EVENT_TRIGGER](state, trigger) {
      state.publishEventTrigger = trigger;
      if (trigger) {
        // Set trigger to false after x time.
        setTimeout(() => {
          state.publishEventTrigger = false;
        }, 1000);
      }
    },
  },
  actions: {
    setPublishEventTrigger(ctx, trigger) {
      ctx.commit(types.NAVIGATION_EVENTS.SET_PUBLISH_EVENT_TRIGGER, trigger);
    },
  },
};
