<template>
  <v-snackbar
    @click.native="closeSnackbar"
    class="mt-15"
    v-model="show"
    :timeout="snackbarMessage.time ? snackbarMessage.time : this.time"
    :color="'velocityBlue'"
    elevation="6"
    text
    top
    multi-line
  >
    <!-- :color="snackbarMessage.type ? snackbarMessage.type : 'velocityBlue'" -->
    <span class="black--text">
      {{ snackbarMessage.message }}
    </span>
    <template v-slot:action="{ attrs }">
      <v-btn text icon v-bind="attrs" @click.stop="closeSnackbar">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export const SnackBarType = {
  SUCCESS: "success",
  FAILURE: "error",
};

export default {
  data() {
    return {
      time: 6000,
      show: false,
    };
  },
  methods: {
    closeSnackbar() {
      console.log("here");
      this.$store.dispatch("setSnackBarMessage", {});
    },
  },
  computed: {
    snackbarMessage() {
      return this.$store.getters.snackbarMessage;
    },
  },
  watch: {
    snackbarMessage(newVal) {
      if (newVal.message) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
};
</script>
