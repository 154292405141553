
<div>
  <v-autocomplete
    :disabled="selectedChart.id ? true : false"
    class="mb-4"
    hide-details
    single-line
    autocomplete="null"
    outlined
    :items="charts"
    v-model="selectedChart"
    label="Venue for Event"
    dense
    hide-no-data
    item-text="name"
    return-object
    color="velocityBlue"
  >
    <template v-slot:item="{ item }">
      <v-flex v-if="item.id" class="mb-2">
        <div style="text-align: center">
          <span style="text-align: center">{{ item.name }}</span>
          <v-flex class="d-flex">
            <v-spacer />
            <v-img
              contain
              max-height="200"
              max-width="200"
              :src="item.publishedVersionThumbnailUrl"
            >
            </v-img>
            <v-spacer />
          </v-flex>
        </div>
      </v-flex>
    </template>
  </v-autocomplete>
  <div
    v-if="meta.seating_type == 'assigned_seating'"
    style="border-radius: 10px"
    id="seatsIOChart"
  ></div>
  <v-divider class="my-5" />
  <SIOTicketBuilder :selectedCategory="selectedCategory" />
</div>
