<template>
  <v-navigation-drawer id="sideMenu" permanent app :mini-variant="miniDrawer">
    <div class="justify-center mb-8 d-flex">
      <OrganizationPickerBtn :miniVariant="miniDrawer" />
    </div>
    <v-flex v-if="!isMobile" class="d-flex justify-right">
      <v-spacer />
      <v-btn @click="miniDrawer = !miniDrawer" outlined icon>
        <v-icon height="40px" width="40px">
          {{ miniDrawer ? "mdi-chevron-right" : "mdi-chevron-left" }}
        </v-icon>
      </v-btn>
      <v-spacer />
    </v-flex>
    <v-divider v-if="!isMobile && overviewApp['name']" class="my-4" />
    <v-list v-if="hasOrg" class="mb-4" nav dense>
      <v-list-item
        @click="routeTo(item)"
        v-for="item in overviewApp.routes"
        :key="item.name"
        link
      >
        <v-tooltip :disabled="!miniDrawer" right>
          <template v-slot:activator="{ on, attrs }">
            <v-flex v-bind="attrs" v-on="on" class="d-flex">
              <v-list-item-icon>
                <v-icon
                  class="pr-2"
                  :style="`${
                    $route.path == item.path
                      ? `color: ${theme.velocityBlue}`
                      : ''
                  }`"
                  >{{ item.icon }}</v-icon
                >
              </v-list-item-icon>
              <v-list-item-title
                class="py-1"
                :style="`${
                  $route.path == item.path
                    ? `color: ${theme.velocityBlue}; font-size: 1em;`
                    : 'font-size: 1em;'
                }`"
                >{{ item.name }}
              </v-list-item-title>
            </v-flex>
          </template>
          <span>{{ item.name }}</span>
        </v-tooltip>
      </v-list-item>
    </v-list>
    <span
      v-if="selectedApp.name != 'Overview' && !miniDrawer"
      class="spanSmallGrey ml-4"
      style="font-weight: bold"
    >
      {{ selectedApp["name"] }}</span
    >
    <v-list
      v-if="selectedApp.routes.length > 0 && selectedApp.name != 'Overview'"
      nav
      dense
    >
      <v-list-item
        @click="routeTo(item)"
        v-for="item in selectedApp.routes"
        :key="item.name"
        link
      >
        <v-tooltip :disabled="!miniDrawer" right>
          <template v-slot:activator="{ on, attrs }">
            <v-flex v-bind="attrs" v-on="on" class="d-flex">
              <v-list-item-icon>
                <v-icon
                  class="pr-2"
                  :style="`${
                    $route.path == item.path
                      ? `color: ${theme.velocityBlue}`
                      : ''
                  }`"
                  >{{ item.icon }}</v-icon
                >
              </v-list-item-icon>
              <v-list-item-title
                class="py-1"
                :style="`${
                  $route.path == item.path
                    ? `color: ${theme.velocityBlue}; font-size: 1em;`
                    : 'font-size: 1em;'
                }`"
                >{{ item.name }}
              </v-list-item-title>
            </v-flex>
          </template>
          <span>{{ item.name }}</span>
        </v-tooltip>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Cart from "../checkout/Cart.vue";
import OrganizationPickerBtn from "../organizations/OrganizationPickerBtn.vue";
export default {
  components: { Cart, OrganizationPickerBtn },
  data() {
    return { miniDrawer: false };
  },
  created() {},
  methods: {
    routeTo(route) {
      if (route == "home") {
        this.$router.push({ path: "/" });
      } else if (route.name === "Login") {
        this.$router.push({ path: "login" });
      } else {
        this.$router.push({ path: route.path });
      }
    },
  },
  computed: {
    userTribe() {
      return this.$store.getters.userTribe;
    },
    selectedApp() {
      return this.$store.getters.selectedApp;
    },
    overviewApp() {
      let overviewIndex = this.$store.getters.apps.findIndex(
        (x) => x.name == "Overview"
      );
      if (overviewIndex != -1) {
        return this.$store.getters.apps[overviewIndex];
      }
    },
    theme() {
      return this.$vuetify.theme.defaults.light;
    },
    chiefedOrgs() {
      return this.$store.getters.chiefedOrgs;
    },
    isMobile() {
      if (this.$store.getters.isMobile) {
        this.miniDrawer = true;
      }
      return this.$store.getters.isMobile;
    },
    hasOrg() {
      return this.$store.getters.hasOrg;
    },
  },
};
</script>
