<template>
  <div class="loading-dialog">
    <div class="loading-dialog__content">
      <div class="loading-spinner"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
};
</script>

<style scoped>
.loading-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.loading-dialog__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-spinner {
  width: 64px;
  height: 64px;
  border: 6px solid #fff;
  border-radius: 50%;
  border-top-color: #276ef1;
  border-right-color: #ffffff;
  border-bottom-color: #276ef1;
  border-left-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
