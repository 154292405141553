<template>
  <div>
    <div v-if="timeslots.length > 0">
      <v-row
        v-for="(timeSlot, index) in timeslots"
        :key="index"
        class="align-center fullBorder rounded mb-2 pa-3 justify-space-between"
        no-gutters
      >
        <v-col cols="12" lg="11" md="11" sm="11">
          <v-row no-gutters>
            <v-col
              cols="12"
              lg="6"
              md="6"
              sm="12"
              :class="isMobile ? '' : 'pr-1'"
            >
              <span class="spanSmallGrey"> Start Time </span>
              <v-text-field
                v-model="timeSlot.startTime"
                color="velocityBlue"
                dense
                hide-details
                label="Start Time"
                outlined
                append-icon=""
                placeholder="12:00"
                single-line
                type="time"
                @click:clear="timeSlot[index].startTime = null"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <span class="spanSmallGrey"> End Time </span>
              <v-text-field
                v-model="timeSlot.endTime"
                color="velocityBlue"
                dense
                hide-details
                label="End Time"
                append-icon=""
                outlined
                placeholder="12:00"
                single-line
                type="time"
                @click:clear="timeSlot[index].endTime = null"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="1" md="1" sm="1">
          <v-btn
            color="error"
            elevation="0"
            text
            x-small
            @click="$emit('removeTimeSlot', index)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-btn
      :class="timeslots.length > 0 ? 'mt-4' : ''"
      class="white--text"
      color="velocityBlue"
      elevation="0"
      rounded
      small
      @click="$emit('addTimeSlot')"
    >
      Add Time Slot
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Event-Time-Slots",
  props: {
    timeslots: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
  },
};
</script>

<style lang="scss" scoped>
#input-86 {
  border: 1px solid #ced4da; // a light grey border
  border-radius: 0.25rem; // rounded corners
  padding: 0.375rem 0.75rem; // some padding inside the input
  font-size: 1rem; // default font size
  line-height: 1.5; // default line height

  &:focus {
    border-color: #80bdff; // a light blue border when focused
    outline: 0; // removing the default focus outline
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); // a glow effect when focused
  }

  // Styles to remove the default spinners and other UI elements from the time input
  &::-webkit-calendar-picker-indicator,
  &::-webkit-inner-spin-button,
  &::-webkit-clear-button {
    display: none;
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }

  // Customizing the placeholder
  &::placeholder {
    color: #6c757d; // a darker grey placeholder
    opacity: 1; // full opacity for the placeholder
  }
}
</style>
