
<div>
  <v-app-bar app min-height="60" class="white" id="appbar">
    <Hamburger
      ref="mobileHamburger"
      v-if="isMobile"
      :toggled="displayMobileNav"
      @toggleMobileNav="toggleMobileNav"
    />
    <v-img height="50px" width="120px" contain src="/u_of_u_logo.png" />
    <AppChoiceButton class="ma-0 pa-0" v-if="hasOrg && isMobile" />
    <ProfileButton v-if="!hasOrg && isMobile" />
    <v-container v-if="!isMobile">
      <v-row
        no-gutters
        style="flex-wrap: nowrap"
        class="d-flex align-content-right justify-right"
      >
        <v-spacer />
        <div
          v-for="item in selectedApp.routes"
          :key="item.name"
          v-if="selectedApp.name == 'Marketplace'"
        >
          <Cart v-if="item.name == 'My Cart'" />

          <v-btn v-else @click="routeTo(item)" text>
            <v-icon class="pr-2" medium> {{ item.icon }} </v-icon>
            {{ item.name }}
          </v-btn>
        </div>
        <NavigationItems
          v-for="item in navigationItems"
          :key="item"
          :item="item"
        />
        <AppChoiceButton v-if="hasOrg" />
        <ProfileButton />
      </v-row>
    </v-container>
    <MobileNav
      :mobileDialog="displayMobileNav"
      @toggleMobileNav="toggleMobileNav"
      @routeTo="routeTo"
    />
  </v-app-bar>
  <v-footer
    v-if="isMobile"
    id="mobileFooter"
    fixed
    :style="`left: ${footerPadding}px !important`"
  >
    <v-flex
      v-if="isMobile && navigationItems && navigationItems.length > 0"
      class="mx-2 d-flex justify-center"
    >
      <NavigationItems
        v-for="item in navigationItems"
        :key="item"
        :item="item"
      />
    </v-flex>
  </v-footer>
</div>
