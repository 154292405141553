<template>
  <div>
    <DefaultEventOptions
      class="mb-2"
      :timezone.sync="eventTimeSetup.timezone"
      :endDate.sync="eventTimeSetup.endDate"
      :endTime.sync="eventTimeSetup.endTime"
      :startDate.sync="eventTimeSetup.startDate"
      :startTime.sync="eventTimeSetup.startTime"
      :disableDatePickers="
        eventTimeSetup?.customDays?.length > 0 ||
        existingTimeslots?.length > 0 ||
        parentEventID != null
      "
      :disableTimePickers="
        existingTimeslots?.length > 0 || parentEventID != null
      "
    ></DefaultEventOptions>

    <div v-if="parentEventID" class="d-flex justify-center mt-6">
      <v-btn class="velocityBlue white--text" @click="viewParentEvent()">
        View Group Event
      </v-btn>
    </div>

    <a
      v-if="
        eventTimeSetup.endTime &&
        existingTimeslots?.length > 0 &&
        !parentEventID
      "
      class="spanBoldSmall"
      @click="openTimeslotTab"
    >
      Add more timeslots for Event
    </a>

    <v-checkbox
      v-if="showRecurringEventsOption"
      v-model="isEventRecurring"
      class="px-2 mb-2"
      color="velocityBlue"
      hide-details
      label="Would you like to set a recurring event?"
    ></v-checkbox>

    <span
      v-if="isEventRecurring && timeslotsToCreate.length > 0"
      class="spanSmallGrey mt-8 pa-3"
    >
      {{
        `${timeslotsToCreate.length} ${
          timeslotsToCreate.length == 1 ? "timeslot" : "timeslots"
        }`
      }}
      will be created.
    </span>

    <div v-if="isEventRecurring">
      <FrequencySelector
        :chosenWeekOfMonth.sync="eventTimeSetup.chosenWeekOfMonth"
        :customDays.sync="eventTimeSetup.customDays"
        :eventFrequency.sync="eventTimeSetup.eventFrequency"
        :selectedDays.sync="eventTimeSetup.selectedDays"
      ></FrequencySelector>

      <!-- <v-checkbox
        v-model="isMultipleEventsPerDay"
        class="px-2 mb-2"
        color="velocityBlue"
        hide-details
        label="Do you want more than one event per day?"
      ></v-checkbox>
      <div v-if="isMultipleEventsPerDay" class="mb-2">
        <div class="spanSmallGrey">Increments & Time Slots</div>
        <v-btn-toggle
          v-model="isInTimeIncrements"
          color="velocityBlue"
          mandatory
        >
          <v-btn
            :value="true"
            class="white--text"
            color="velocityBlue"
            rounded
            small
            >Increments</v-btn
          >
          <v-btn
            :value="false"
            class="white--text"
            color="velocityBlue"
            rounded
            small
            >Time Slots</v-btn
          >
        </v-btn-toggle>
      </div> -->

      <!-- <div v-if="isInTimeIncrements && isMultipleEventsPerDay">
        <span class="spanSmallGrey"> Time Increments (Minutes) </span>
        <v-select
          v-model="eventTimeSetup.eventIncrement"
          :items="minuteIncrements"
          dense
          hide-details
          outlined
        ></v-select>
      </div>
      <div v-else class="pa-3">
        <EventTimeSlots
          :timeslots.sync="eventTimeSetup.timeslots"
          @addTimeSlot="addTimeSlot"
          @removeTimeSlot="removeTimeSlot($event)"
        >
        </EventTimeSlots>
      </div> -->

      <div
        v-if="timeslotsToCreate.length > 0"
        class="d-flex justify-center mt-6"
      >
        <v-btn
          class="velocityBlue white--text"
          @click="saveTimeslotsToServer()"
        >
          Create Timeslots
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultEventOptions from "./components/DefaultEventOptions.vue";
import FrequencySelector from "./components/FrequencySelector.vue";
import EventTimeSlots from "./components/CreateTimeSlots.vue";
import {
  convertDateTimeToISOWithTimezone,
  convertUTCDateToTimezone,
} from "@/utility/helperFunctions";

export default {
  name: "Event-Reoccurrence",
  components: {
    DefaultEventOptions,
    EventTimeSlots,
    FrequencySelector,
  },
  props: {
    meta: Object,
    existingTimeslots: [],
    parentEventID: String,
    showRecurringEventsOption: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    eventTimeSetup: {
      chosenWeekOfMonth: [],
      customDays: [],
      endDate: null,
      endTime: null,
      eventIncrement: "",
      eventFrequency: "never",
      selectedDays: [],
      startDate: null,
      startTime: null,
      timezone: null,
      timeslots: [],
    },
    isInTimeIncrements: true,
    minuteIncrements: [...Array.from({ length: 12 }, (_, i) => (i + 1) * 5)],
    recurringEventStatus: false,
    originalStartDate: null,
    originalEndDate: null,
    timeslotsToCreate: [],
    isMultipleEventsPerDay: false,
  }),
  created() {
    this.initializeData();
  },
  methods: {
    openTimeslotTab() {
      this.$emit("openTimeslotTab");
    },
    viewParentEvent() {
      this.$router.push({
        name: "EventBuilderExpanded",
        params: { eventID: this.parentEventID },
      });
    },
    addTimeSlot() {
      this.eventTimeSetup.timeslots.push({ startTime: null, endTime: null });
    },
    initializeData() {
      this.eventTimeSetup.timezone = this.meta.timezone;
      this.formatDates();
    },
    formatDates() {
      if (this.meta?.startTime) {
        this.originalStartDate = convertUTCDateToTimezone(
          this.meta.startTime,
          this.meta.timezone
        );
        this.eventTimeSetup.startDate =
          this.originalStartDate.toFormat("yyyy-MM-dd");
        this.eventTimeSetup.startTime =
          this.originalStartDate.toFormat("HH:mm");
      }

      if (this.meta?.endTime) {
        this.originalEndDate = convertUTCDateToTimezone(
          this.meta.endTime,
          this.meta.timezone
        );
        this.eventTimeSetup.endDate =
          this.originalEndDate.toFormat("yyyy-MM-dd");
        this.eventTimeSetup.endTime = this.originalEndDate.toFormat("HH:mm");
      }
    },
    createTimeslotObjects() {
      this.timeslotsToCreate = [];
      // Create the timeslot objects that will be saved to the server
      for (var i = 0; i < this.sortedSelectedDays.length; i++) {
        if (this.timeslotsToCreate.length < 300) {
          this.timeslotsToCreate.push({
            id: vGUID(),
            start_time: convertDateTimeToISOWithTimezone(
              this.sortedSelectedDays[i],
              this.eventTimeSetup.startTime,
              this.meta.timezone
            ),
            end_time: convertDateTimeToISOWithTimezone(
              this.sortedSelectedDays[i],
              this.eventTimeSetup.endTime,
              this.meta.timezone
            ),
          });
        } else {
          this.$store.dispatch("setSnackBarMessage", {
            message:
              "You have hit the limit of 300 recurring Events for one Event.",
            type: "FAILURE",
          });
          return this.timeslotsToCreate.length;
        }
      }
    },
    saveTimeslotsToServer() {
      vDo(
        "event/timeslot/create_event_timeslots",
        {
          event_id: this.$route.params.eventID,
          time_slots: this.timeslotsToCreate,
        },
        (response) => {
          if (response["x"] == 200) {
            this.$emit("saveEvent");
            this.$store.dispatch("setSnackBarMessage", {
              message: `Successfully created ${this.timeslotsToCreate.length} timeslots for this event.`,
              type: "SUCCESS",
            });
          } else {
            this.$store.dispatch("setSnackBarMessage", {
              message: "Something went wrong, please try again later.",
              type: "FAILURE",
            });
            this.$store.dispatch("setSnackBarMessage", {
              message: response["data"],
              type: "FAILURE",
            });
          }
        }
      );
    },
    removeTimeSlot(index) {
      this.eventTimeSetup.timeslots.splice(index, 1);
    },
  },
  computed: {
    completedDates() {
      let dates = {
        startDateLocal: this.completeStartDate,
        endDateLocal: this.completeEndDate,
        startDateUTC: this.completeStartDate ? this.completeStartDate : "",
        endDateUTC: this.completeEndDate ? this.completeEndDate : "",
        timezone: this.eventTimeSetup.timezone,
      };

      return dates;
    },
    completeStartDate() {
      if (this.eventTimeSetup.startDate && this.eventTimeSetup.startTime) {
        return convertDateTimeToISOWithTimezone(
          this.eventTimeSetup.startDate,
          this.eventTimeSetup.startTime,
          this.meta.timezone
        );
      } else {
        return "";
      }
    },
    completeEndDate() {
      if (this.eventTimeSetup.endDate && this.eventTimeSetup.endTime) {
        return convertDateTimeToISOWithTimezone(
          this.eventTimeSetup.endDate,
          this.eventTimeSetup.endTime,
          this.meta.timezone
        );
      } else {
        return "";
      }
    },
    isEventRecurring: {
      get() {
        return this.recurringEventStatus;
      },
      set(newValue) {
        this.recurringEventStatus = newValue;

        this.eventTimeSetup.customDays = [];
        this.eventTimeSetup.selectedDays = [];
        this.eventTimeSetup.chosenWeekOfMonth = [];
        this.eventTimeSetup.timeslots = [];

        if (!this.recurringEventStatus) {
          this.eventTimeSetup.eventFrequency = "never";
          return;
        }

        this.eventTimeSetup.eventFrequency = "Every Day";
      },
    },
    sortedSelectedDays() {
      // Create a copy of the selectedDays array and sort the copy
      const sortedArray = this.eventTimeSetup.customDays.slice().sort();
      return sortedArray;
    },
  },
  watch: {
    meta: {
      handler(newVal, oldVal) {
        this.initializeData();
      },
      deep: true,
    },
    eventFrequency(newVal) {
      if (newVal === "Every Day") {
        this.eventTimeSetup.selectedDays = [];
      }
    },
    eventTimeSetup: {
      handler(newVal, oldVal) {
        this.meta.timezone = newVal.timezone;
        if (newVal != oldVal) {
          this.formatDates();
        }
        //Set event date to begins on and ends on
        if (this.sortedSelectedDays.length > 0) {
          let customDates = this.sortedSelectedDays;
          let newStartDate = customDates[0];
          this.eventTimeSetup.startDate = newStartDate;
          if (customDates.length > 1) {
            let newEndDate = customDates[customDates.length - 1];
            this.eventTimeSetup.endDate = newEndDate;
          } else {
            let newEndDate = customDates[0];
            this.eventTimeSetup.endDate = newEndDate;
          }
        }
        this.createTimeslotObjects();
      },
      deep: true,
    },
  },
};
</script>
