<template>
  <v-menu id="profileMenu" open-on-hover bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn @click="routeToProfile" v-bind="attrs" v-on="on" text>
        <v-icon medium> mdi-account-circle </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item>
        <v-btn text @click="routeToProfile">
          <v-icon class="pr-2"> mdi-cog-outline </v-icon>
          <span>Profile</span></v-btn
        >
      </v-list-item>
      <v-list-item v-if="userTribe.id">
        <v-btn text @click="signOut">
          <v-icon class="pr-2"> mdi-power </v-icon>
          <span>Log Out</span></v-btn
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "ProfileButton",
  data: () => ({}),
  mounted() {},
  methods: {
    routeToProfile() {
      this.$router.push({ path: "/profile" });
    },
    signOut() {
      vSignOut();
    },
  },
  computed: {
    userTribe() {
      return this.$store.getters.userTribe;
    },
  },
  watch: {},
};
</script>
