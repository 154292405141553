<template>
  <div>
    <v-alert dense :type="type">
      <span v-html="message"></span>
    </v-alert>
  </div>
</template>

<script>
// TYPES: success, info, warning, error
export default {
  name: "AlertComponent",
  components: {},
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
};
</script>
