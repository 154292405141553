<template>
  <div v-if="!allowCodeEntry" class="login-form-container">
    <v-card-title v-if="showBackButton" class="ml-0 pl-0">
      <v-btn outlined icon @click="hideSelf()">
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <v-spacer />
    </v-card-title>
    <div class="text-h5 font-weight-bold">Login</div>
    <div class="text-body-1 mt-2">
      Login with your mobile phone to join the Community.
    </div>
    <div class="d-flex align-center mt-6">
      <div style="width: 12rem" class="pr-2">
        <v-autocomplete
          outlined
          v-model="countryCode"
          :items="countryCodes"
          label="Country"
          prepend-icon="mdi-map-marker-outline"
          item-text="name"
          item-value="key"
          :disabled="loading"
        ></v-autocomplete>
      </div>
      <div style="width: 100%">
        <v-text-field
          :loading="loading"
          outlined
          v-model="phone"
          label="Phone"
          placeholder="Phone"
          :rules="[validPhone]"
          @input="watchNumber"
          :disabled="loading"
        ></v-text-field>
      </div>
    </div>
    <div v-if="newAccount" style="width: 100%">
      <v-form ref="loginForm">
        <v-row>
          <v-col cols="6">
            <v-text-field
              :loading="loading"
              outlined
              v-model="firstName"
              label="First Name"
              placeholder="John"
              :rules="[validFirst]"
              :disabled="loading"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :loading="loading"
              outlined
              v-model="lastName"
              label="Last Name"
              placeholder="Smith"
              :rules="[validLast]"
              :disabled="loading"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-text-field
          :loading="loading"
          outlined
          v-model="email"
          label="Email"
          placeholder="JohnSmith1995@gmail.com"
          :rules="[validEmail]"
          :disabled="loading"
        ></v-text-field>
        <v-text-field
          :loading="loading"
          outlined
          v-model="username"
          label="Username"
          placeholder="JohnSmith1995"
          :disabled="loading"
        ></v-text-field>
      </v-form>
      <div>
        <v-flex class="d-flex">
          <v-checkbox dense v-model="termsAndConditions"></v-checkbox>
          <span class="mt-2"
            >I Agree to the
            <a href="https://www.velocityaccess.io/terms/" target="_blank">
              Terms and Conditions</a
            ></span
          >
        </v-flex>
        <v-flex d-flex class="justify-center">
          <v-btn :disabled="!termsAndConditions" @click="submitEmail" outlined>
            Submit
          </v-btn>
        </v-flex>
      </div>
    </div>
  </div>
  <div v-else class="login-form-container">
    <div class="text-h5 font-weight-bold">Login</div>
    <div class="text-body-1 mt-2">
      Enter the authorization code that was just sent to your mobile phone and
      email address
    </div>
    <div class="d-flex align-center justify-center mt-6">
      <div v-if="allowCodeEntry" style="width: 50%">
        <v-text-field
          :loading="loading"
          outlined
          v-model="code"
          label="Auth Code"
          placeholder="Code"
          @input="watchCode"
          :disabled="loading"
          :rules="[validCode]"
          maxlength="6"
        ></v-text-field>
      </div>
    </div>
    <div class="d-flex align-center justify-center mt-6">
      <v-btn outlined @click="reset()">
        <v-icon class="pr-3"> mdi-arrow-left </v-icon> Back
      </v-btn>
    </div>
  </div>
</template>

<script>
import { validatePhoneNumber, validateEmail } from "@/utility/helperFunctions";
export default {
  name: "Login",
  components: {},
  props: {
    showBackButton: Boolean,
  },
  data() {
    return {
      countryCodes: [{ name: "US +1", key: "+1" }],
      countryCode: "+1",
      phone: "",
      email: "",
      code: "",
      validPhone: true,
      validEmail: true,
      validFirst: true,
      validLast: true,
      validCode: true,
      validated: false,
      loading: false,
      account: "",
      allowCodeEntry: false,
      newAccount: false,
      termsAndConditions: false,
      firstName: "",
      lastName: "",
      username: "",
    };
  },
  mounted() {},
  methods: {
    reset() {
      this.countryCode = "+1";
      this.phone = "";
      this.code = "";
      this.validPhone = true;
      this.validCode = true;
      this.loading = false;
      this.account = "";
      this.allowCodeEntry = false;
      this.newAccount = false;
      this.firstName = "";
      this.lastName = "";
      this.username = "";
    },
    watchNumber() {
      this.newAccount = false;
      this.phone = this.phone.replaceAll("-", "");
      this.account = "";
      if (this.phone.length >= 10) {
        this.validPhone = validatePhoneNumber(this.phone);
        if (this.validPhone) {
          let phoneObj = {
            phone: this.phone,
            country: this.countryCode,
          };
          this.loading = true;
          vDo("auth/phone", phoneObj, (response) => {
            this.account = response.data.ref;
            if (this.account) {
              this.getCode();
            } else {
              this.newAccount = true;
              this.loading = false;
            }
          });
        }
      }
    },
    validateFields() {
      this.validEmail = validateEmail(this.email);
      if (!this.validEmail) {
        this.validEmail = "Please enter a valid Email";
      }
      this.validFirst = this.firstName.length > 0 ? true : "Required";
      this.validLast = this.lastName.length > 0 ? true : "Required";
      this.validated = this.$refs.loginForm.validate();
    },
    submitEmail() {
      if (this.termsAndConditions) {
        this.validateFields();

        if (this.validated) {
          this.getCode();
        }
      } else {
        this.$store.dispatch("setSnackBarMessage", {
          message: "Must agree to Terms and Conditions to login",
          type: "FAILURE",
        });
      }
    },
    watchCode() {
      if (this.code.length == 6) {
        let authObj = {
          country: this.countryCode,
          auth: this.phone,
          code: this.code,
          email: this.email,
          first_name: this.firstName,
          last_name: this.lastName,
          name: this.username,
        };
        this.loading = true;
        vDo("auth/auth", authObj, (response) => {
          if (response.x == 200) {
            let data = response["data"];
            if (data["sid"].length > 20) {
              vG("sid", data["sid"]);
              vG("uid", data["uid"]);
              if (data["tribe"]) {
                vG("tribe", data["tribe"]);
              } else {
                vG("tribe", data["uid"]);
              }
              vCheck((response) => {
                this.login();
              });
            }
          } else {
            this.validCode = response.data;
          }
          this.loading = false;
        });
      } else {
        this.validCode = true;
      }
    },
    login() {
      this.$store.dispatch("loadUser");
      this.$store.dispatch("loadChiefedOrgs");
      this.$store.dispatch("claimReservedAssets", this.rerouteToWallet);
      this.hideSelf();
    },
    rerouteToWallet() {
      this.$router.push({ name: "My Wallet" });
    },
    hideSelf() {
      this.$store.dispatch("showLoginPage", false);
    },
    validateNumber(phone) {
      return validatePhoneNumber(phone) || "Please enter valid Phone Number";
    },
    getCode() {
      let phoneObj = {
        auth: this.phone,
        country: this.countryCode,
      };
      if (this.email) {
        phoneObj["email"] = this.email;
      }
      this.loading = true;
      vDo("auth/knock", phoneObj, (response) => {
        if (response["x"] == 200) {
          this.loading = false;
          this.allowCodeEntry = true;
        } else {
          this.loading = false;
          this.validEmail = response["data"];
        }
      });
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  watch: {},
};
</script>

<style scoped>
.login-form-container {
  max-width: 40rem;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
}
</style>
