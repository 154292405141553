<template>
  <v-btn elevation="0" class="transparent pt-1">
    <div class="menu-btn" @click="toggleMenu">
      <div class="btn-line"></div>
      <div class="btn-line"></div>
      <div class="btn-line"></div>
    </div>
  </v-btn>
</template>

<script>
export default {
  data() {
    return {
      btn: null,
    };
  },
  mounted() {
    this.btn = document.querySelector(".menu-btn");
  },
  methods: {
    toggleMenu() {
      if (!this.toggled) {
        this.btn.classList.add("close");
        this.$emit("toggleMobileNav");
      } else {
        this.btn.classList.remove("close");
        this.$emit("toggleMobileNav");
      }
    },
    setMenu() {
      if (this.toggled) {
        this.btn.classList.add("close");
      } else {
        this.btn.classList.remove("close");
      }
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
  },
  watch: {
    toggled(newVal, oldVal) {
      this.setMenu();
    },
  },
  props: {
    toggled: Boolean,
  },
};
</script>

<style scoped>
.menu-btn {
  z-index: 3;
  cursor: pointer;
  transition: all 0.5s ease-out;
}

/* Styling the hamburger lines */
.menu-btn .btn-line {
  width: 20px;
  height: 2px;
  margin: 0 0 5px 0;
  background: black;
  transition: all 0.5s ease-out;
}

/* Adding transform to the X */
.menu-btn.close {
  transform: rotate(180deg);
}

/* Styling the three lines
        to make it an X */
.menu-btn.close .btn-line:nth-child(1) {
  transform: rotate(45deg) translate(9px, 8px);
}

.menu-btn.close .btn-line:nth-child(2) {
  opacity: 0;
}

.menu-btn.close .btn-line:nth-child(3) {
  transform: rotate(-45deg) translate(2px, -1px);
}

/* Adding a transition delay to the
       4 items in the navigation menu */
.nav-item:nth-child(1) {
  transition-delay: 0.1s;
}

.nav-item:nth-child(2) {
  transition-delay: 0.2s;
}

.nav-item:nth-child(3) {
  transition-delay: 0.3s;
}

.nav-item:nth-child(4) {
  transition-delay: 0.4s;
}
</style>
