
<v-flex class="d-flex flex-column">
  <span class="spanBoldMedium"> Price Categories </span>
  <div class="my-2" v-for="(price, index) in pricing" :key="price.id">
    <v-flex d-flex>
      <v-flex class="mr-2" xs4 sm4 md4 lg4>
        <span v-if="index == 0" class="spanBoldSmall">Category</span>
        <v-text-field
          dense
          class="pa-0 ma-0 mt-1"
          v-model="price.name"
          clear-icon="mdi-close-circle"
          outlined
          single-line
          placeholder="Adult"
          color="velocityBlue"
          hide-details
        />
      </v-flex>
      <v-flex class="mx-2" xs4 sm4 md4 lg4>
        <span v-if="index == 0" class="spanBoldSmall">Price</span>
        <v-text-field
          type="number"
          dense
          class="pa-0 ma-0 mt-1"
          v-model.number="price.price"
          clear-icon="mdi-close-circle"
          outlined
          single-line
          placeholder="10"
          color="velocityBlue"
          hide-details
        >
        </v-text-field>
      </v-flex>
      <v-flex xs2 sm2 md2 lg2>
        <span v-if="index == 0" class="spanBoldSmall">Enabled</span>
        <v-flex class="d-flex justify-center">
          <v-btn
            class="my-0 py-0 mt-1"
            icon
            :color="`${price.active ? 'blue' : ''}`"
            @click="price.active = !price.active"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  {{
                    price.active
                      ? "mdi-checkbox-marked"
                      : "mdi-checkbox-blank-outline"
                  }}
                </v-icon>
              </template>
              {{ price.active ? "Purchaseable" : "Not Purchaseable" }}
            </v-tooltip>
          </v-btn>
        </v-flex>
      </v-flex>
      <v-flex xs2 sm2 md2 lg2>
        <v-btn
          :class="index == 0 ? 'mt-7' : 'mt-1'"
          icon
          @click.stop="deletePricing(price)"
        >
          <v-icon> mdi-trash-can-outline </v-icon>
        </v-btn>
      </v-flex>
    </v-flex>
  </div>
  <a class="spanBoldSmall mt-2" @click="addPricingTier">
    + Add Pricing Category
  </a>
</v-flex>
