
<v-menu id="profileMenu" bottom offset-y open-on-hover>
  <template v-slot:activator="{ on, attrs }">
    <v-btn text v-bind="attrs" v-on="on">
      <v-icon medium> mdi-dots-grid</v-icon>
    </v-btn>
  </template>
  <v-card :max-width="maxWidth" :min-width="minWidth" class="pa-2">
    <div class="mx-2">
      <span class="spanBoldXSmall mr-12"> Velocity Apps </span>
      <v-row align="stretch" class="d-flex mt-2">
        <v-col
          v-for="item in apps"
          v-if="item.name != 'Overview'"
          :key="item.name"
          class="colMaxWidth"
          @click="setSelectedApp(item)"
        >
          <v-flex class="d-flex flex-column">
            <v-icon
              :class="`${item.color} pa-2`"
              color="white"
              style="font-size: 50px; border-radius: 10px !important"
              >{{ item.icon }}
            </v-icon>
            <span
              class="textCaption"
              style="
                justify-content: center;
                vertical-align: middle;
                text-align: center;
              "
              v-html="item.name"
            >
            </span>
          </v-flex>
        </v-col>
        <v-col class="colMaxWidth" @click="signOut">
          <v-flex class="d-flex flex-column">
            <v-icon
              :class="`velocityBlue pa-2`"
              color="white"
              style="font-size: 50px; border-radius: 10px !important"
              >mdi-logout
            </v-icon>
            <span
              class="textCaption"
              style="
                justify-content: center;
                vertical-align: middle;
                text-align: center;
              "
              v-html="`Logout`"
            >
            </span>
          </v-flex>
        </v-col>
      </v-row>
    </div>
  </v-card>
</v-menu>
