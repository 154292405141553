
<v-dialog
  id="mobileDialog"
  v-model="mobileDialog"
  fullscreen
  transition="dialog-bottom-transition"
  content-class="elevation-0"
  persistent
>
  <v-card style="overflow-y: scroll">
    <v-list class="mb-15">
      <div class="px-15 pt-10 pb-5">
        <span class="spanBoldMedium" style="font-weight: bold">
          General
        </span>
      </div>
      <v-list-item @click="routeTo('profile')">
        <v-list-item-content class="text-left px-15 bottomBorder"
          ><v-flex d-flex>
            <v-list-item-icon>
              <v-icon class="pr-2">mdi-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="font-size: 1em"
              >Profile</v-list-item-title
            >
          </v-flex>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="hasOrg"
        v-for="item in overviewApp.routes"
        :key="item.name"
        link
      >
        <v-list-item-content
          @click="routeTo(item)"
          class="text-left px-15 bottomBorder"
        >
          <v-flex d-flex>
            <v-list-item-icon>
              <v-icon
                class="pr-2"
                :style="`${
                  selectedApp.name == item.name
                    ? `color: ${theme.velocityBlue}`
                    : ''
                }`"
                >{{ item.icon }}</v-icon
              >
            </v-list-item-icon>
            <v-list-item-title
              :style="`${
                selectedApp.name == item.name
                  ? `color: ${theme.velocityBlue}; font-size: 1em;`
                  : 'font-size: 1em;'
              }`"
              >{{ item.name }}</v-list-item-title
            >
          </v-flex>
        </v-list-item-content>
      </v-list-item>

      <div class="px-15 pt-10 pb-5">
        <span
          v-if="selectedApp.name != 'Overview'"
          class="spanBoldMedium"
          style="font-weight: bold"
        >
          {{ selectedApp["name"] }}</span
        >
      </div>
      <v-list-item
        v-if="selectedApp.name != 'Overview'"
        v-for="item in selectedApp.routes"
        :key="item.name"
        link
      >
        <v-list-item-content
          @click="routeTo(item)"
          class="text-left px-15 bottomBorder"
        >
          <v-flex d-flex>
            <v-list-item-icon>
              <v-icon
                class="pr-2"
                :style="`${
                  selectedApp.name == item.name
                    ? `color: ${theme.velocityBlue}`
                    : ''
                }`"
                >{{ item.icon }}</v-icon
              >
            </v-list-item-icon>
            <v-list-item-title
              :style="`${
                selectedApp.name == item.name
                  ? `color: ${theme.velocityBlue}; font-size: 1em;`
                  : 'font-size: 1em;'
              }`"
              >{{ item.name }}</v-list-item-title
            >
          </v-flex>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</v-dialog>
