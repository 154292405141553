<template>
  <v-flex class="d-flex flex-column">
    <span class="spanBoldMedium"> Price Categories </span>
    <div class="my-2" v-for="(price, index) in pricing" :key="price.id">
      <v-flex d-flex>
        <v-flex class="mr-2" xs4 sm4 md4 lg4>
          <span v-if="index == 0" class="spanBoldSmall">Category</span>
          <v-text-field
            dense
            class="pa-0 ma-0 mt-1"
            v-model="price.name"
            clear-icon="mdi-close-circle"
            outlined
            single-line
            placeholder="Adult"
            color="velocityBlue"
            hide-details
          />
        </v-flex>
        <v-flex class="mx-2" xs4 sm4 md4 lg4>
          <span v-if="index == 0" class="spanBoldSmall">Price</span>
          <v-text-field
            type="number"
            dense
            class="pa-0 ma-0 mt-1"
            v-model.number="price.price"
            clear-icon="mdi-close-circle"
            outlined
            single-line
            placeholder="10"
            color="velocityBlue"
            hide-details
          >
          </v-text-field>
        </v-flex>
        <v-flex xs2 sm2 md2 lg2>
          <span v-if="index == 0" class="spanBoldSmall">Enabled</span>
          <v-flex class="d-flex justify-center">
            <v-btn
              class="my-0 py-0 mt-1"
              icon
              :color="`${price.active ? 'blue' : ''}`"
              @click="price.active = !price.active"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    {{
                      price.active
                        ? "mdi-checkbox-marked"
                        : "mdi-checkbox-blank-outline"
                    }}
                  </v-icon>
                </template>
                {{ price.active ? "Purchaseable" : "Not Purchaseable" }}
              </v-tooltip>
            </v-btn>
          </v-flex>
        </v-flex>
        <v-flex xs2 sm2 md2 lg2>
          <v-btn
            :class="index == 0 ? 'mt-7' : 'mt-1'"
            icon
            @click.stop="deletePricing(price)"
          >
            <v-icon> mdi-trash-can-outline </v-icon>
          </v-btn>
        </v-flex>
      </v-flex>
    </div>
    <a class="spanBoldSmall mt-2" @click="addPricingTier">
      + Add Pricing Category
    </a>
  </v-flex>
</template>

<script>
export default {
  props: { product: Object, eventMeta: Object },
  components: {},
  name: "TicketTypePricing",
  data: () => ({
    pricing: [],
  }),
  created() {
    this.$store.dispatch("loadNewProductPricingSchema");
  },
  methods: {
    addPricingTier() {
      if (Object.keys(this.newProductPricingSchema).length > 0) {
        let pricingSchemaCopy = { ...this.newProductPricingSchema };
        pricingSchemaCopy["id"] = vGUID();
        this.pricing.push(pricingSchemaCopy);
      }
    },
    deletePricing(price) {
      vDo(
        "product/pricing/delete_product_pricing",
        { price_id: price.id },
        (response) => {
          if (response["x"] == 200) {
            let priceIndex = this.pricing.findIndex((x) => x.id == price.id);
            if (priceIndex != -1) {
              this.pricing.splice(priceIndex, 1);
            }
          } else {
            let priceIndex = this.pricing.findIndex((x) => x.id == price.id);
            if (
              priceIndex != -1 &&
              response.data == "WARNING: Product Price does not exist"
            ) {
              this.pricing.splice(priceIndex, 1);
            } else {
              this.$store.dispatch("setSnackBarMessage", {
                message: response.data,
                type: "FAILURE",
              });
            }
          }
        }
      );
    },
    initializePricing() {
      if (this.product.pricing && this.product.pricing.length > 0) {
        this.pricing = this.product.pricing;
      } else {
        this.addPricingTier();
      }
    },
  },
  computed: {
    newProductPricingSchema() {
      return this.$store.getters.newProductPricingSchema;
    },
  },
  watch: {
    pricing: {
      handler(newVal, oldVal) {
        if (newVal.length > 0) {
          this.$emit("savePricing", this.pricing);
        }
      },
      deep: true,
    },
    newProductPricingSchema(newVal) {
      if (newVal) {
        this.initializePricing();
      }
    },
    product: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.initializePricing();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
