
<v-app
  v-if="networkLoaded && !showLogin"
  id="app"
  style="background-color: #f9f9f9"
>
  <div id="topEl" />
  <NavigationBar />
  <SideMenu v-if="showSidemenu && selectedApp.name != 'Marketplace'" />
  <SnackBar />
  <LoadingSpinner v-if="showLoadingIndicator" />
  <v-main :style="`padding-bottom: ${bodyPadding}px !important`">
    <router-view :key="$route.fullPath" />
  </v-main>
</v-app>
<v-app v-else-if="networkLoaded">
  <Login :showBackButton="true" />
</v-app>
