<template>
  <v-autocomplete
    :disabled="disabled"
    outlined
    dense
    v-model="selectedTimezone"
    :items="timezones"
    :label="label"
    item-text="name"
    item-value="timezone"
  ></v-autocomplete>
</template>

<script>
export default {
  name: "TimezonePicker",
  props: {
    label: {
      type: String,
      default: () => {
        return "Select a timezone";
      },
    },
    existingTimezone: {
      String,
    },
    disabled: {
      Boolean,
    },
  },
  data: () => ({
    selectedTimezone: null,
    timezones: [],
  }),
  created() {
    this.initializeTimezones();
    this.setDefaultTimezone();
  },
  methods: {
    initializeTimezones() {
      this.timezones = Intl.supportedValuesOf("timeZone")
        .filter((timeZone) => timeZone.startsWith("America/")) // Filter for America timezones only
        .map((timeZone) => {
          const offset = new Intl.DateTimeFormat("en", {
            timeZone: timeZone,
            timeZoneName: "shortOffset",
          })
            .formatToParts()
            .find((part) => part.type === "timeZoneName").value;
          const timeZoneAbbreviation = new Intl.DateTimeFormat("en", {
            timeZone: timeZone,
            timeZoneName: "long",
          })
            .formatToParts()
            .find((part) => part.type === "timeZoneName").value;
          return {
            timezone: timeZone,
            name: `${timeZoneAbbreviation} (${offset}) - ${timeZone}`,
          };
        });
    },
    setDefaultTimezone() {
      if (!this.existingTimezone) {
        // Attempt to set the user's timezone automatically
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // Check and set the timezone if it exists in the list
        const matchingTimezone = this.timezones.find(
          (tz) => tz.timezone === userTimezone
        );
        if (matchingTimezone) {
          this.selectedTimezone = matchingTimezone;
        } else {
          // If there's no direct match, you could implement logic to guess the user's timezone
          // based on known mappings, or default to a manual selection prompt.
          // Example: Defaulting to Mountain Time for users in Utah not detected as such
          this.selectedTimezone = this.timezones.find(
            (tz) => tz.timezone === "America/Denver"
          );
        }
      } else {
        this.selectedTimezone = this.existingTimezone;
      }
    },
  },
  watch: {
    selectedTimezone(newVal) {
      if (newVal) {
        if (newVal.timezone) {
          this.$emit("timezoneSelected", this.selectedTimezone.timezone);
        } else {
          this.$emit("timezoneSelected", this.selectedTimezone);
        }
      }
    },
  },
};
</script>
