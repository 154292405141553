<template>
  <v-btn @click="routeToCheckout" text>
    <v-icon class="pr-2" medium> mdi-cart-outline </v-icon>
    My Cart ({{ this.cartCount }})
  </v-btn>
</template>

<script>
export default {
  components: {},
  name: "Cart",
  data: () => ({}),
  created() {},
  destroyed() {},
  mounted() {},
  methods: {
    routeToCheckout() {
      this.$router.push({
        name: "Checkout",
      });
    },
  },
  computed: {
    cartCount() {
      return this.$store.getters.cartCount;
    },
  },
};
</script>

<style scoped></style>
