
<v-form ref="defaultEvent">
  <v-col :class="`${isMobile ? 'mt-4 py-0' : 'py-0'}`">
    <span class="spanSmallGrey"> Timezone for Event </span>
    <TimezonePicker
      :disabled="disableTimePickers"
      @timezoneSelected="timezoneSelected"
      :existingTimezone="timezone"
      :label="``"
    />
  </v-col>
  <v-row :no-gutters="isMobile" class="ma-0">
    <v-col class="py-0" cols="12" lg="6" md="6" sm="12">
      <span class="spanSmallGrey"> Begins on </span>
      <v-menu
        v-model="startDateMenu"
        ref="menu"
        :close-on-content-click="false"
        min-width="auto"
        offset-y
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :disabled="disableDatePickers"
            :placeholder="getPlaceholderDates('today')"
            v-model="startDate"
            clearable
            color="velocityBlue"
            dense
            hide-details
            outlined
            single-line
            v-bind="attrs"
            @change="$emit('update:startDate', $event)"
            v-on="on"
            @click:clear="
              $emit('update:startDate', null);
              $emit('update:endDate', null);
            "
          ></v-text-field>
        </template>
        <v-date-picker
          :disabled="disableDatePickers"
          :value="startDate"
          color="velocityBlue"
          no-title
          scrollable
          @change="$emit('update:startDate', $event)"
        >
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="startDateMenu = false">
            Cancel
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col class="py-0" cols="12" lg="6" md="6" sm="12">
      <span class="spanSmallGrey"> Ends on </span>
      <v-menu
        v-model="endDateMenu"
        ref="menu"
        :close-on-content-click="false"
        min-width="auto"
        offset-y
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :disabled="disableDatePickers"
            :placeholder="getPlaceholderDates('tomorrow')"
            :value="endDate"
            clearable
            color="velocityBlue"
            dense
            hide-details
            outlined
            single-line
            v-bind="attrs"
            @change="$emit('update:endDate', $event)"
            v-on="on"
            @click:clear="$emit('update:endDate', null)"
          ></v-text-field>
        </template>
        <v-date-picker
          :disabled="disableDatePickers"
          :min="startDate"
          :value="endDate"
          color="velocityBlue"
          no-title
          scrollable
          @change="$emit('update:endDate', $event)"
        >
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="endDateMenu = false">
            Cancel
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
  <v-row :no-gutters="isMobile" class="ma-0">
    <v-col cols="12" lg="6" md="6" sm="12">
      <span class="spanSmallGrey"> Start Time </span>
      <v-text-field
        :disabled="disableTimePickers"
        :value="startTime"
        @change="$emit('update:startTime', $event)"
        color="velocityBlue"
        dense
        hide-details
        label="Start Time"
        outlined
        placeholder="12:00"
        single-line
        type="time"
        @click:clear="$emit('update:startTime', null)"
      ></v-text-field>
    </v-col>

    <v-col cols="12" lg="6" md="6" sm="12">
      <span class="spanSmallGrey"> End Time </span>
      <v-text-field
        :disabled="disableTimePickers"
        :value="endTime"
        @change="
          validateEndTime;
          $emit('update:endTime', $event);
        "
        :rules="endTimeError ? ['End time should be after start time'] : []"
        color="velocityBlue"
        dense
        hide-details
        label="End Time"
        outlined
        placeholder="12:00"
        single-line
        type="time"
      ></v-text-field>
    </v-col>
  </v-row>
</v-form>
