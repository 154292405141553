<template>
  <div>
    <v-autocomplete
      :disabled="selectedChart.id ? true : false"
      class="mb-4"
      hide-details
      single-line
      autocomplete="null"
      outlined
      :items="charts"
      v-model="selectedChart"
      label="Venue for Event"
      dense
      hide-no-data
      item-text="name"
      return-object
      color="velocityBlue"
    >
      <template v-slot:item="{ item }">
        <v-flex v-if="item.id" class="mb-2">
          <div style="text-align: center">
            <span style="text-align: center">{{ item.name }}</span>
            <v-flex class="d-flex">
              <v-spacer />
              <v-img
                contain
                max-height="200"
                max-width="200"
                :src="item.publishedVersionThumbnailUrl"
              >
              </v-img>
              <v-spacer />
            </v-flex>
          </div>
        </v-flex>
      </template>
    </v-autocomplete>
    <div
      v-if="meta.seating_type == 'assigned_seating'"
      style="border-radius: 10px"
      id="seatsIOChart"
    ></div>
    <v-divider class="my-5" />
    <SIOTicketBuilder :selectedCategory="selectedCategory" />
  </div>
</template>

<script>
import AlertComponent from "@/components/app/AlertComponent.vue";
import TicketType from "./TicketType.vue";
import SIOTicketBuilder from "./SIOTicketBuilder.vue";
export default {
  components: {
    AlertComponent,
    TicketType,
    SIOTicketBuilder,
  },
  props: { meta: Object },
  data: () => ({
    selectedChart: {},
    categories: [],
    showFreeWarning: false,
    selectedObject: {},
    selectedCategory: {},
    chartObject: null,
  }),
  created() {
    this.loadSeatCharts();
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    initializeData() {
      if (this.meta.integrations && this.meta.integrations.sio) {
        if (this.meta["integrations"]["sio"].chart && !this.selectedChart.id) {
          this.selectedChart = this.meta["integrations"]["sio"].chart;
        }
        if (this.meta["integrations"]["sio"]["event"]) {
          this.categories =
            this.meta["integrations"]["sio"]["event"].categories;
        }
        if (!this.$store.getters.workspaceKeys[this.appContextOrg.id]) {
          this.$store.dispatch("fetchOrgWorkspacekey", this.appContextOrg.id);
        } else {
          this.embedSeatingChart();
        }
      } else {
        this.meta["integrations"] = { sio: {} };
      }
    },
    loadSeatCharts() {
      if (this.appContextOrg) {
        let chartObject = { limit: 20, org_id: this.appContextOrg.id };
        this.$store.dispatch("loadSeatCharts", chartObject);
      }
    },
    setChartData() {
      this.$emit("setSelectedChart", this.meta);
    },
    embedSeatingChart() {
      if (
        !this.meta.integrations ||
        !this.meta.integrations.sio ||
        !this.meta.integrations.sio.event ||
        !this.workspaceKey
      ) {
        return;
      }
      let chartEl = document.getElementById("seatsIOChart");
      if (chartEl) {
        let seatsObj = {
          divId: "seatsIOChart",
          workspaceKey: this.workspaceKey,
          event: this.meta.integrations.sio.event.key,
          categoryFilter: {
            enabled: true,
            multiSelect: false,
          },
          onObjectClicked: (object) => {
            //Only select one category a time to choose pricing
            if (this.selectedObject.id) {
              this.chartObject.deselectObjects(this.selectedObject.id);
            }
            this.loadCategoryQuantity(object);
          },
        };
        if (!this.chartObject) {
          this.chartObject = new seatsio.SeatingChart(seatsObj).render();
        }
      }
    },
    async updateChartConfig() {
      await this.chartObject.clearSelection();

      if (this.selectedCategory["key"]) {
        this.chartObject.changeConfig({
          filteredCategories: [this.selectedCategory.key], // this will deselect objects that belong to an unavailable category
        });
      }
    },
    loadCategoryQuantity(object) {
      vDo(
        "integration/seats/get_event_capacity",
        {
          org_id: this.appContextOrg.id,
          event_key: this.meta.integrations.sio.event.key,
        },
        (response) => {
          let eventCapacity = response["data"];
          let availableSeatsByCategory =
            eventCapacity["available"]["byCategoryKey"];
          let availableCount = availableSeatsByCategory[object.category.key];
          let quantity = availableCount || null;
          object.category.quantity = quantity;
          this.selectedCategory = object.category;
        }
      );
    },
  },
  computed: {
    appContextOrg() {
      return this.$store.getters.appContextOrg;
    },
    workspaceKey() {
      return this.$store.getters.workspaceKeys[this.appContextOrg.id];
    },
    charts() {
      return this.$store.getters.charts;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
  },
  watch: {
    selectedCategory(newVal) {
      if (newVal) {
        this.updateChartConfig();
      }
    },
    appContextOrg(newVal) {
      if (newVal) {
        this.loadSeatCharts();
      }
    },
    meta: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.initializeData();
        }
      },
      deep: true,
    },
    selectedChart(newVal, oldVal) {
      if (newVal.id != oldVal.id) {
        this.meta["integrations"]["sio"]["chart"] = newVal;

        this.setChartData();
      }
    },
    workspaceKey(newVal) {
      if (newVal) {
        this.embedSeatingChart();
      }
    },
  },
};
</script>

<style></style>
