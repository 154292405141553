
<v-dialog
  fullscreen
  transition="dialog-bottom-transition"
  v-model="creatingEvent"
  persistent
>
  <v-card class="pb-12">
    <v-container :class="isMobile ? 'ma-0 pa-0' : ''">
      <v-flex class="d-flex justify-right">
        <v-spacer />
        <v-btn
          :class="isMobile ? 'mr-5 mt-2' : ''"
          color="black"
          icon
          @click="closeDialog()"
        >
          <v-icon large color="black" style="font-size: 24px"
            >mdi-close</v-icon
          >
        </v-btn>
      </v-flex>
      <v-row no-gutters class="pb-15 mb-15 d-flex justify-center">
        <v-col cols="12" lg="7" md="10" sm="12">
          <div class="mx-3">
            <div class="py-2 mb-0 pb-0">
              <span class="spanBoldMedium">Create an Event</span>
            </div>
            <div class="mb-5">
              <span class="spanSmallGrey">
                Events start as a draft. You can edit this information later
                on.
              </span>
            </div>
            <span class="spanSmallGrey mt-8"> Event Name </span>
            <v-text-field
              dense
              class="pt-0 mt-1"
              v-model="meta.name"
              clear-icon="mdi-close-circle"
              clearable
              outlined
              single-line
              placeholder="Improv Comedy Night"
              type="text"
              @click:clear="meta.name = ''"
              color="velocityBlue"
            >
            </v-text-field>
            <EventDatePicker
              ref="datePicker"
              :show-recurring-events-option="false"
              :meta="meta"
            />
            <EventSeatingTypeSelector :meta="meta" @setMeta="setMeta" />
            <!-- Places Search -->
            <Addresses
              :address="meta.address"
              :label="'event'"
              :tribe="appContextOrg.id"
              class="my-6"
            />
          </div>
          <div class="pb-6">
            <span class="spanBoldSmall"> {{ `Event Settings` }} </span>
            <v-divider class="mt-5 mb-4" />
            <span class="spanSmallGrey">
              {{ `Who can access this event` }}
            </span>
            <v-radio-group v-model="eventVisibility" row>
              <v-radio
                color="velocityBlue"
                label="Public"
                value="public"
              ></v-radio>
              <v-radio
                color="velocityBlue"
                label="Private"
                value="private"
              ></v-radio>
            </v-radio-group>
          </div>
          <div>
            <v-btn class="velocityBlue white--text" block @click="saveEvent">
              Create event as draft
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</v-dialog>
