
<v-flex
  :class="`d-flex flex-column pa-2 px-4 ${
    small ? 'fullBorder' : 'bottomBorder'
  }`"
>
  <span class="spanBoldSmall"> {{ address["full_name"] }} </span>
  <span class="textParagraph1"> {{ address["complete_address"] }} </span>
  <span v-if="!small" class="textParagraph1"> {{ address["country"] }} </span>
  <span v-if="!small" class="textParagraph1">
    Phone Number: {{ address["phone"] }}
  </span>
  <!-- <span v-if="defaultAddr == 'true'" class="textParagraph1"> Default Address </span> -->
</v-flex>
